const paths = {
    EN: 'https://edbkcg.kcg.gov.tw/en/cp.aspx?n=F9FE55B870969935',
    EDBKCG: 'https://edbkcg.kcg.gov.tw',
    GUIDE: '/guide',
    TEACH: '/teach',
    TEACH_BUSINESS: '/teach?type=business',
    QA: '/qna',
    DOWNLOAD: '/download',
    TRANSCRIPTION: '/transcription',
    SIGN_COMPANY: '/sign?step1=1',
    SIGN_BUSINESS: '/sign?step1=2',
    SIGN_COMPANY_STEP2_1: '/sign?step1=1&step2=1',
    SIGN_COMPANY_STEP2_2: '/sign?step1=1&step2=2',
    SIGN_BUSINESS_STEP2_1: '/sign?step1=2&step2=4',
    SIGN_BUSINESS_STEP2_2: '/sign?step1=2&step2=5',
    SIGN_COMPANY_NEED_DOCUMENT: 'https://gcis.nat.gov.tw/mainNew/matterAction.do?method=showFile&fileNo=t70043_p',
    CASE_PROCESS_SEARCH: 'https://serv.gcis.nat.gov.tw/caseSearch/list/QueryCsmmCaseList/queryCsmmCaseList.do',
    LAND_USE_CONTROL: 'https://urban-web.kcg.gov.tw/KDA/web_page/UBA020200.jsp',
    COMPANY_SING_CLOUD: 'https://serv.gcis.nat.gov.tw/cfm/user/login/main.do',
    FOREIGN_COMPANY: 'https://gcis.nat.gov.tw/mainNew/subclassNAction.do?method=getFile&pk=21',
    GOOGLE_MAP: 'https://goo.gl/maps/Jzz2hKM62QeYX8Rd8',
    PRIVACY: '/privacy',
    SECURITY_POLICY: '/security-policy',
    COPYRIGHT: '/copyright',
    SIGN_ONLINE: 'https://onestop.nat.gov.tw/oss/welcome/Welcome/index.do',
    PRE_CHECK: 'https://onestop.nat.gov.tw/oss/web/Show/download_T.do',
    LIMITED_PARTNERSHIP: 'https://gcis.nat.gov.tw/mainNew/subclassNAction.do?method=getFile&pk=727',
    FILE_COMPANY_SIGN_FLOW: '/files/公司設立流程_English.docx',
    FILE_BUSINESS_SIGN_FLOW: '/files/商業設立應備文件_English.docx',
    LAWS: '/sign/1/1/1/21/公司設立?tab=decree',
}

export default paths
