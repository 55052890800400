import React from 'react'
import { CusWrapper, CusSection, CusLink } from 'src/components'
import { Row, Col } from 'antd'
import { ReactComponent as IconLinkOut } from 'src/assets/images/icon-link-out.svg'
import paths from 'src/router/paths.config'
const HrefLink = (props: { href?: string; children: React.ReactNode }) => {
    return (
        <CusLink href={props.href} icon={<IconLinkOut />} iconPosition='right' iconSpace={6}>
            {props.children}
        </CusLink>
    )
}

const Page = () => {
    return (
        <CusWrapper>
            <CusSection className='section-document-content'>
                <h1>隱私權保護政策</h1>
                <p>
                    非常歡迎您光臨「高雄市政府經濟發展局網站」（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
                </p>
                <h3>一、隱私權保護政策的適用範圍</h3>
                <p>隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。</p>
                <h3>二、個人資料的蒐集、處理及利用方式</h3>
                <p>
                    當您洽辦本網站業務或參與本網站活動時，我們將視業務或活動性質請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
                    本網站在您使用熱門話題等互動性功能時，會保留您所提供的姓名、性別、年齡等資料。 於一般瀏覽時，為統計瀏覽人數 ,
                    本網站將記錄您的點選資料，做為我們增進網站服務的參考依據，而此資料將不會、也無法和您的個人資料進行比對。
                    為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
                </p>
                <h3>三、資料之保護</h3>
                <p>
                    本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站資料，如因業務需要有必要委託本網站相關單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
                </p>
                <h3>四、網站對外的相關連結</h3>
                <p>本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站，但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。</p>
                <h3>五、Cookie 之使用</h3>
                <p>
                    為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie 的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie
                    的寫入，但可能會導至網站某些功能無法正常執行。
                </p>
                <h3>六、隱私權保護政策之修正</h3>
                <p>本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。</p>
            </CusSection>
        </CusWrapper>
    )
}

export default Page
