import React, { useEffect } from 'react'
import { CusBreadcrumb, CusSection } from 'src/components'
import { TypeBreadcrumbRoutes } from 'src/@types/Breadcrumb'
import { useLocation, matchRoutes } from 'react-router-dom'
import { routesConfig } from 'src/router/routes.config'
type Props = {
    children: React.ReactNode
    hideBreadcrumb?: boolean
    breadcrumb?: TypeBreadcrumbRoutes
    metaTitle?: string
    style?: any
    className?: string
}

const Wrapper = (props: Props) => {
    const { children, hideBreadcrumb = false, breadcrumb, metaTitle, style, className } = props

    const location = useLocation()
    const routes = matchRoutes(routesConfig, location) as any[]

    useEffect(() => {
        const title = metaTitle ? metaTitle : routes[routes.length - 1]?.route?.name
        document.title = '高雄市政府經濟發展局 | ' + title
        document.head.querySelector('meta[property="og:title"]')?.setAttribute('content', '高雄市政府經濟發展局 | ' + title)
        document.head.querySelector('meta[name="DC.Title"]')?.setAttribute('content', '高雄市政府經濟發展局 | ' + title)
    }, [location, routes, metaTitle])

    return (
        <div className={`cus-wrapper ${className}`} style={style}>
            {!hideBreadcrumb && (
                <CusSection>
                    <CusBreadcrumb items={breadcrumb} />
                </CusSection>
            )}
            {children}
        </div>
    )
}

export default Wrapper
