import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { CusWrapper } from 'src/components'
import { SectionAllDocuments, SectionOtherNeed, SectionBusinessHeader } from 'src/sections'

const BusinessHome = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { type } = useParams()
    const typeName = type === '1' ? '公司' : '商業'
    const [tabs, setTabs] = useState(
        type === '1'
            ? [
                  { label: '有限公司', key: '1' },
                  { label: '股份有限公司', key: '2' },
                  { label: '外資公司、辦事處', key: '3', href: 'https://gcis.nat.gov.tw/mainNew/subclassNAction.do?method=getFile&pk=21' },
                  { label: '閉鎖性公司', key: '4', href: 'https://gcis.nat.gov.tw/mainNew/classNAction.do?method=list&pkGcisClassN=15' },
              ]
            : [
                  { label: '獨資組織', key: '4' },
                  { label: '合夥組織', key: '5' },
              ]
    )

    return (
        <CusWrapper breadcrumb={[{ breadcrumbName: `${typeName}登記` }]} metaTitle={`選擇${typeName}型態`}>
            <SectionBusinessHeader title={`選擇${typeName}型態`} links={{ guide: `/sign?step1=${type}`, all: `/sign/all/${type}` }} active={1} hideLinks={!type} />
            <SectionAllDocuments tabs={tabs} />
            <SectionOtherNeed business={type === '2'} />
        </CusWrapper>
    )
}

export default BusinessHome
