import { ReactComponent as IconPlusBlue } from 'src/assets/images/plus-blue.svg'
import { ReactComponent as IconMinusBlue } from 'src/assets/images/minus-blue.svg'

interface QNAItemProps {
    title: string
    content: string
    showContent: boolean
    isFirst: boolean
    onChangeShowContent: () => void
}
export default function QNAItem(props: QNAItemProps) {
    return (
        <div
            className='cus-qa-item'
            role={'listitem'}
            style={{ padding: '20px 10px', border: '1px solid #C9C9C9', borderRight: 'none', borderLeft: 'none', borderTop: props.isFirst ? 'none' : undefined }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='cus-qa-item-title' style={{ display: 'flex', wordBreak: 'break-all' }}>
                    <span style={{ color: '#266AD8', marginRight: '10px' }}>Q</span>
                    <span style={{ color: '#202020', fontWeight: '500' }}>{props.title}</span>
                </div>
                <div
                    role={'button'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        props.onChangeShowContent()
                    }}
                >
                    {props.showContent ? <IconMinusBlue role={'button'} aria-label='隱藏詳情' /> : <IconPlusBlue role={'button'} aria-label='顯示詳情' />}
                </div>
            </div>
            {props.showContent && <div role={'alert'} style={{ paddingLeft: '24px', paddingTop: '10px' }} dangerouslySetInnerHTML={{ __html: props.content }}></div>}
        </div>
    )
}
