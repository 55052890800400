import React, { useMemo } from 'react'

type Props = {
    type: 'check' | 'number'
    children: React.ReactNode
    style?: any
    color?: 'gray' | 'default'
}

const Text = (props: Props) => {
    const { type, children, style, color } = props

    const myClass = useMemo(() => {
        let payload = 'cus-text'
        if (type) payload += ` is-type-${type}`
        if (color) payload += ` is-color-${color}`
        return payload
    }, [type])

    return (
        <div className={myClass} style={style}>
            {type === 'check' && <img src='/images/icon/check.svg' alt='icon-check' />}
            {children}
        </div>
    )
}

export default Text
