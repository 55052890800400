import { Modal } from 'antd'
// import { setToken } from 'src/utils'
const errorHandler = async (res: any) => {
    const code = res.data && (res.data.code as number | undefined | string)
    const status = res.status as number | string
    const message = res.message as undefined | null | string
    let errorCode: string | number = status
    let errorMessage = message || res.data.message
    if (status === 200 && code) {
        errorCode = code
        errorMessage = res.data.message as string
    }
    if (res?.request?.responseType === 'blob') {
        const blob = new Blob([res?.data])
        const msg = await blob.text()
        if (['未設置一鍵下載資料。', '未設置一鍵下載文檔。'].includes(msg)) {
            Modal.info({
                title: '未設置一鍵下載資料。',
                content: '',
                okText: '確認',
            })
        }
    }
    if (status !== 200 && !errorMessage) {
        errorMessage = res?.data
    }
    const errorBody = `
系統錯誤訊息%0D%0A%0D%0A
請求網址：${res?.request.responseURL}%0D%0A
錯誤代碼：${status}%0D%0A
請求編號：${res.config.headers['x-request-id']}%0D%0A
錯誤訊息：${errorMessage}`

    switch (errorCode) {
        case 500:
        case 501:
        case 502:
        case 403:
        case 'network':
            Modal.error({
                title: (
                    <div>
                        <span>
                            發生錯誤，請聯繫系統管理員
                            <br />
                            並截圖寄到電子信箱
                        </span>
                        <a href={`mailto:simplson@kcg.gov.tw?cc=kimm7430@kcg.gov.tw&subject=[網站錯誤回報]-高雄市政府經濟發展局&body=${errorBody}`}> simplson@kcg.gov.tw </a>
                        <span>或電話07-3368333轉3185呂先生、3186曾先生通知。</span>
                    </div>
                ),
                width: 500,
                wrapClassName: 'cus-modal-error',
                content: (
                    <ul>
                        <li>錯誤代碼：{status}</li>
                        <li>請求編號：{res.config.headers['x-request-id']}</li>
                        <li>錯誤訊息：{errorMessage}</li>
                    </ul>
                ),
            })
            console.error('[ERROR: bc0756] errorMessage', 'src/plugins/axios/utils/errorHandler.ts', errorMessage)
            break
        // case 502:
        //     Modal.error({
        //         content: errorMessage,
        //     })
        //     // Modal.alert(errorMessage, '', {
        //     //     confirmButtonText: '確定',
        //     //     customClass: 'cus-message',
        //     //     dangerouslyUseHTMLString: true,
        //     //     showClose: false,
        //     // })
        //     break
        case 401:
            // setToken(null)
            // window.location.href = process.env.PUBLIC_URL
            break
        case 404:
            Modal.error({
                title: (
                    <div>
                        <span>
                            請求網址不存在，請聯繫系統管理員
                            <br />
                            並截圖寄到電子信箱
                        </span>
                        <a href={`mailto:simplson@kcg.gov.tw?cc=kimm7430@kcg.gov.tw&subject=[網站錯誤回報]-高雄市政府經濟發展局&body=${errorBody}`}> simplson@kcg.gov.tw </a>
                        <span>或電話07-3368333轉3185呂先生、3186曾先生通知。</span>
                    </div>
                ),
                width: 500,
                wrapClassName: 'cus-modal-error',
                content: (
                    <ul>
                        <li>錯誤代碼：{status}</li>
                        <li>請求編號：{res.config.headers['x-request-id']}</li>
                        <li>請求網址：{res.request.responseURL}</li>
                        <li>錯誤訊息：{errorMessage}</li>
                    </ul>
                ),
            })
            break

        default:
            break
    }
    return errorCode !== 200 ? { errorCode, errorMessage } : 0
}

export default errorHandler
