import React, { useState, useEffect } from 'react'
import { Breadcrumb as AntdBreadCrumb } from 'antd'
import { Link, useLocation, matchRoutes } from 'react-router-dom'
import { routesConfig } from 'src/router/routes.config'
import { HomeFilled } from '@ant-design/icons'
import { TypeBreadcrumbRoutes } from 'src/@types/Breadcrumb'
import { ReactComponent as HouseSvg } from 'src/assets/images/house.svg'
import Icon from '@ant-design/icons'
type Props = {
    items?: TypeBreadcrumbRoutes
}

const Breadcrumb = (props: Props) => {
    const { items } = props
    const [breadCrumbItems, setBreadcrumbItems] = useState<any>([{ path: '/', breadcrumbName: <HouseSvg className='breadcrumb-home' /> }])
    const location = useLocation()

    const itemRender = (route: any, params: any, routes: any, paths: any) => {
        let path = '/'
        const last = routes.indexOf(route) === routes.length - 1
        paths.forEach((row: string) => {
            if (['&', '?'].includes(row[0])) path += row
            else path += row
        })

        return last ? <span>{route.breadcrumbName}</span> : <Link to={path}>{route.breadcrumbName}</Link>
    }

    useEffect(() => {
        if (Array.isArray(items) && items.length) {
            setBreadcrumbItems([{ path: '/', breadcrumbName: <HouseSvg className='breadcrumb-home' /> }, ...items])
        } else {
            const routes = matchRoutes(routesConfig, location) as any[]
            const breads: any[] = [{ path: '/', breadcrumbName: <HouseSvg className='breadcrumb-home' /> }]
            const titleList: string[] = []
            if (routes) {
                routes.forEach((row: any) => {
                    if (row?.route?.path) {
                        breads.push({
                            path: row.pathnameBase,
                            breadcrumbName: row.route.name,
                        })
                        titleList.push(row.route.name)
                    }
                })
                setBreadcrumbItems(breads)
            }
        }
    }, [location, items])
    return (
        <div className='breadcrumb-container'>
            <img
                id='AC'
                src='/images/icon/blind-brick-black.svg'
                width='12'
                alt='導盲磚'
                accessKey='C'
                className='u-scroll-margin-top'
                onClick={() => {
                    document.querySelector(`#AC`)?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                    })
                }}
            />
            <AntdBreadCrumb separator='>' itemRender={itemRender} routes={breadCrumbItems}></AntdBreadCrumb>
        </div>
    )
}

export default Breadcrumb
