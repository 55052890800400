import React, { useState, useEffect, useCallback } from 'react'
import axios from 'src/plugin/axios'
import { useLoading } from './useLoading'

type Props = {
    url: string
    wait: boolean
    method: 'post' | 'get' | 'put' | 'delete' | 'patch'
    params: object
    loading: boolean
    format: any
    downloadFile: string
    defaultData?: any
}

const useAxios = (props: any): any => {
    const { loading: isShowLoading = true, downloadFile, defaultData = null, method = 'get', responseType } = props
    const [data, setData] = useState(defaultData)
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useLoading()
    const [error, setError] = useState(null)
    const [httpStatus, setHttpStatus] = useState(0)
    const [code, setCode] = useState(0)
    const callAPI = useCallback(
        async (params: any) => {
            try {
                setLoading(true)
                if (isShowLoading) setLoader(true)
                const data = params || null
                const axiosConfig: any = {
                    method,
                    url: props?.url || props,
                }
                method === 'get' ? (axiosConfig.params = data) : (axiosConfig.data = data)

                if (responseType) {
                    axiosConfig.responseType = responseType
                }
                const res = await axios(axiosConfig)
                if (responseType === 'blob') {
                    setData(res?.data)
                } else {
                    const payload = props.format ? props.format(res?.data?.data) : res?.data?.data
                    setData(payload)
                }
                setCode(res?.data?.code)
                setHttpStatus(res?.status)
                setError(null)
                setLoading(false)
                if (downloadFile) {
                    const blob = new Blob([res?.data])
                    const text = await blob.text()
                    if (!['未設置一鍵下載資料。', '未設置一鍵下載文檔。'].includes(text)) {
                        const fileName = downloadFile
                        const eLink = document.createElement('a')
                        eLink.download = fileName
                        eLink.style.display = 'none'
                        eLink.href = URL.createObjectURL(blob)
                        document.body.appendChild(eLink)
                        eLink.click()
                        URL.revokeObjectURL(eLink.href)
                        document.body.removeChild(eLink)
                    } else {
                        throw { message: '未設置一鍵下載資料' }
                    }
                }

                if (isShowLoading) setLoader(false)
            } catch (error: any) {
                console.error('[ERROR: 53e157] err', 'src/hook/useAxios.ts', error)
                setData(defaultData)
                setCode(0)
                setLoading(false)
                if (isShowLoading) setLoader(false)
                setError(error?.message || error?.error?.message || error)
                setHttpStatus(-1)
                throw { ...error }
            }
        },
        [props]
    )

    const execute = async (params: any) => {
        try {
            await callAPI(params)
        } catch (error: any) {
            throw { ...error }
        }
    }

    useEffect(() => {
        if (!props.wait && method === 'get') callAPI(props?.params)
    }, [])

    return [{ data, loading, error, httpStatus, code }, execute]
}

export default useAxios
export { axios }
