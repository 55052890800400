import { Row, Col, Space, Input, Pagination, Spin } from 'antd'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CusLink, CusSection, CusWrapper, EmptyData, QNAItem } from 'src/components'
import { ReactComponent as IconDownloadBlue } from 'src/assets/images/download-blue.svg'
import { changeURLQuery, getUrlQuery, setURLQuery } from 'src/utils/url'
import { DictionaryItem } from 'src/@types/dictionary'
import useAxios from 'src/hook/useAxios'
import BreakpointContext from 'src/context/BreakpointContext'
import useRWD from 'src/hook/useRWD'
import { scrollToMain } from 'src/utils'
const QA = () => {
    const breakpointContext = useContext(BreakpointContext)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const nav = useNavigate()
    const { isMobile } = useRWD()

    const QuestionCategoryProps = useRef({
        url: '/Dictionary/GetList?type=QuestionCategoryWeb',
        loading: false,
    })
    const [{ data: QuestionCategoryData, loading: QuestionCategoryLoading }] = useAxios(QuestionCategoryProps.current)
    const QuestionCategoryDataShow = useMemo(() => {
        const _QCD: DictionaryItem[] = QuestionCategoryData
        if (!_QCD) {
            return []
        }
        return _QCD.sort((a, b) => a.seq - b.seq)
    }, [QuestionCategoryData])

    const routeLocation = useLocation()

    const maxResultCount = 10
    const [pageNumber, pageNumberSet] = useState(1)
    const [categoryId, categoryIdSet] = useState(searchQuery.get('category') || '')
    function renderCategory() {
        if (!QuestionCategoryDataShow) {
            return <></>
        }

        return (
            <Space size={30}>
                {QuestionCategoryDataShow.map(_item => (
                    <CusLink
                        type='button'
                        theme={categoryId === _item.value.toString() ? 'primary' : 'gray'}
                        circle
                        to={{ search: setURLQuery(routeLocation.search, { category: _item.value, page: 1 }) }}
                        key={_item.value}
                    >
                        {_item.description}
                    </CusLink>
                ))}
            </Space>
        )
    }
    useEffect(() => {
        if (QuestionCategoryDataShow.length) {
            if (routeLocation.search) {
                const _categoryId = searchQuery.get('category')
                categoryIdSet(_val => _categoryId || QuestionCategoryDataShow[0].value.toString())
                pageNumberSet(val => Number(searchQuery.get('page')) || 1)
            } else {
                categoryIdSet(QuestionCategoryDataShow[0].value.toString())
            }
        }
    }, [routeLocation.search, QuestionCategoryDataShow])

    const QuestionDownloadProps = useRef({
        method: 'post',
        url: '/Question/GetDownload',
        loading: true,
        responseType: 'blob',
    })

    const [{ data: QuestionDownloadData, loading: QuestionDownloadLoading }, getDownloadExecute] = useAxios(QuestionDownloadProps.current)
    function renderGetDownloadBtn() {
        return (
            <div
                role={'button'}
                style={{ height: '100%', display: 'flex', alignItems: 'center', color: '#266ad8', marginLeft: '40px', cursor: 'pointer' }}
                onClick={() => {
                    getDownloadExecute({
                        category: Number(categoryId),
                        keyWord: searchStr,
                    })
                }}
            >
                <IconDownloadBlue /> <span style={{ marginLeft: '8px', wordBreak: 'keep-all' }}>一鍵下載</span>
            </div>
        )
    }
    useEffect(() => {
        if (QuestionDownloadData) {
            const blob = new Blob([QuestionDownloadData])
            const fileName = '常見問題.xlsx'
            const eLink = document.createElement('a')
            eLink.download = fileName
            eLink.style.display = 'none'
            eLink.href = URL.createObjectURL(blob)
            document.body.appendChild(eLink)
            eLink.click()
            URL.revokeObjectURL(eLink.href)
            document.body.removeChild(eLink)
        }
    }, [QuestionDownloadData])

    const AxiosProps = useRef({
        method: 'post',
        url: '/Question/Search',
        loading: false,
    })
    const [{ data, loading, error, code }, getListExecute] = useAxios(AxiosProps.current)
    const listDataShowMemo = useMemo<any[]>(() => {
        if (data && data.items) {
            return data.items
        } else {
            return []
        }
    }, [data])
    const listDataTotal = useMemo(() => data?.totalCount || 0, [data])

    let keywordInit = ''
    if (routeLocation.search) {
        keywordInit = getUrlQuery(routeLocation.search, 'keyword') || ''
    }
    const [searchStr, searchStrSet] = useState(keywordInit)
    const [showContentList, showContentListSet] = useState<string[]>([])
    useEffect(() => {
        if (routeLocation.search) {
            const showids = getUrlQuery(routeLocation.search, 'showids')
            if (showids) {
                showContentListSet(showids.split('-'))
            }

            const _page = getUrlQuery(routeLocation.search, 'page')
            if (_page) {
                pageNumberSet(Number(_page))
            }

            const _keyword = getUrlQuery(routeLocation.search, 'keyword')
            if (_keyword) {
                searchStrSet(_keyword)
            }
        }
    }, [])
    useEffect(() => {
        if (categoryId) {
            getListExecute({
                skipCount: (pageNumber - 1) * maxResultCount,
                maxResultCount: maxResultCount,
                category: Number(categoryId),
                keyword: searchStr,
            })
        }
    }, [categoryId, pageNumber, searchStr])

    return (
        <CusWrapper breadcrumb={[{ breadcrumbName: '常見問題' }]}>
            <CusSection>
                <Row style={{ marginBottom: breakpointContext?.isDesktop ? '30px' : '20px' }}>
                    <Col>
                        <h1>常見問題</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: breakpointContext?.isDesktop ? '60px' : '30px' }}>
                    <Col span={breakpointContext?.isDesktop ? 8 : 24}>
                        <Input.Search
                            id={scrollToMain.id}
                            placeholder='請輸入您要查找的問題'
                            enterButton
                            allowClear
                            defaultValue={searchStr}
                            onSearch={_val => {
                                searchStrSet(_val)
                                pageNumberSet(1)
                                const urlSearchStr = changeURLQuery(routeLocation.search, 'page', 1)
                                nav({ search: changeURLQuery(urlSearchStr, 'keyword', _val) })
                            }}
                        ></Input.Search>
                    </Col>
                </Row>
                <div
                    style={{
                        overflowX: 'auto',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '10px',
                        marginLeft: '-20px',
                        marginRight: '-20px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    }}
                >
                    {renderCategory()}

                    {listDataShowMemo.length > 0 && renderGetDownloadBtn()}
                </div>
                <Spin spinning={loading}>
                    {code ? (
                        <>
                            <Row style={{ marginBottom: '60px' }}>
                                <Col role={'list'} span={24}>
                                    {listDataShowMemo.length === 0 && <EmptyData title='查無資料' />}
                                    {listDataShowMemo.map((_item, index) => {
                                        const idStr = _item.id.toString()
                                        return (
                                            <QNAItem
                                                key={idStr}
                                                isFirst={index === 0}
                                                title={_item.title}
                                                content={_item.content}
                                                showContent={showContentList.includes(idStr)}
                                                onChangeShowContent={() => {
                                                    let showIdList = []
                                                    if (showContentList.includes(idStr)) {
                                                        showContentList.splice(showContentList.indexOf(idStr), 1)
                                                        showIdList = [...showContentList]
                                                        showContentListSet(showIdList)
                                                    } else {
                                                        showIdList = [...showContentList, idStr]
                                                        showContentListSet(showIdList)
                                                    }
                                                    nav({ search: changeURLQuery(routeLocation.search, 'showids', showIdList.join('-')) })
                                                }}
                                            />
                                        )
                                    })}
                                </Col>
                            </Row>
                            {listDataShowMemo.length > 0 && (
                                <Row justify={'center'}>
                                    <Col>
                                        <Pagination
                                            showLessItems={isMobile ? true : false}
                                            current={pageNumber}
                                            pageSize={maxResultCount}
                                            showSizeChanger={false}
                                            total={listDataTotal}
                                            onChange={_page => {
                                                pageNumberSet(_page)
                                                nav({ search: changeURLQuery(routeLocation.search, 'page', _page) })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    ) : null}
                </Spin>
            </CusSection>
        </CusWrapper>
    )
}

export default QA
