import React, { useEffect, useRef, useState } from 'react'
import { CusLink, CusSection } from 'src/components'
import { Space } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import useAxios from 'src/hook/useAxios'
type Item = {
    label: string
    to?: string
    href?: string
    show?: boolean
}

type Props = {
    items: Item[]
    active: number
    type: 'registration' | 'question'
    data: { keyword?: string; id: string | number | undefined }
    downloadFileUrl?: string
    onDownloadClick?: any
}

const SignHeaderLinks = (props: Props) => {
    const { items = [], active, type, data, downloadFileUrl, onDownloadClick } = props
    const [myActive, setMyActive] = useState(active)
    // const downloadAPI = useRef({
    //     method: 'post',
    //     loading: false,
    //     url: '',
    //     responseType: 'blob',
    //     downloadFile,
    // })
    // if (type === 'registration') downloadAPI.current.url = '/Question/GetDownload'
    // if (type === 'question') downloadAPI.current.url = '/Registration/GetDownload'
    // const [{ data: downloadData, loading, error }, executeDownload] = useAxios(downloadAPI.current)

    const handleClick = (index: number) => {
        setMyActive(index + 1)
    }

    const handleDownload = () => {
        // executeDownload({ id: data?.id })
    }

    useEffect(() => {
        setMyActive(active)
    }, [active])

    return (
        <CusSection space={30}>
            <div className='cus-section-sign-header-links'>
                <div className='links-wrapper'>
                    {items.map(
                        (item: Item, index: number) =>
                            (!Object.prototype.hasOwnProperty.call(item, 'show') || item.show) && (
                                <CusLink
                                    key={index}
                                    type='button'
                                    theme={`${myActive === index + 1 ? 'primary' : 'gray'}`}
                                    circle
                                    noBreak
                                    to={item?.to}
                                    href={item?.href}
                                    onClick={() => handleClick(index)}
                                >
                                    {item.label}
                                </CusLink>
                            )
                    )}
                </div>
                {(downloadFileUrl || onDownloadClick) && (
                    <CusLink icon={<DownloadOutlined />} noBreak style={{ marginLeft: '12px' }} onClick={onDownloadClick} href={downloadFileUrl}>
                        一鍵下載
                    </CusLink>
                )}
            </div>
        </CusSection>
    )
}

export default SignHeaderLinks
