import { Row, Col, Space, Input, Pagination, Spin } from 'antd'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CusLink, CusSection, CusWrapper, DownloadItem, EmptyData } from 'src/components'
import { changeURLQuery, getUrlQuery, setURLQuery } from 'src/utils/url'
import { DictionaryItem } from 'src/@types/dictionary'
import useAxios from 'src/hook/useAxios'
import BreakpointContext from 'src/context/BreakpointContext'
import useRWD from 'src/hook/useRWD'
import { scrollToMain } from 'src/utils'

const Download = () => {
    const breakpointContext = useContext(BreakpointContext)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const { isMobile } = useRWD()
    const nav = useNavigate()

    const categoryProps = useRef({
        url: '/Dictionary/GetList?type=DocumentCategoryWeb',
        loading: true,
    })
    const [{ data: categoryData, loading: categoryLoading }] = useAxios(categoryProps.current)
    const categoryDataShow = useMemo(() => {
        const _QCD: DictionaryItem[] = categoryData
        if (!_QCD) {
            return []
        }
        return _QCD.sort((a, b) => a.seq - b.seq)
    }, [categoryData])

    const routeLocation = useLocation()

    const maxResultCount = 10
    const [pageNumber, pageNumberSet] = useState(1)
    const [categoryId, categoryIdSet] = useState(searchQuery.get('category') || '')

    function renderCategory() {
        if (!categoryDataShow) {
            return <></>
        }

        return (
            <Space size={30}>
                {categoryDataShow.map(_item => (
                    <CusLink
                        type='button'
                        theme={categoryId === _item.value.toString() ? 'primary' : 'gray'}
                        circle
                        to={{ search: setURLQuery(routeLocation.search, { category: _item.value, page: 1 }) }}
                        key={_item.value}
                    >
                        {_item.description}
                    </CusLink>
                ))}
            </Space>
        )
    }

    useEffect(() => {
        if (categoryDataShow.length) {
            if (routeLocation.search) {
                const _categoryId = getUrlQuery(routeLocation.search, 'category')
                categoryIdSet(_val => _categoryId || categoryDataShow[0].value.toString())
                pageNumberSet(val => Number(searchQuery.get('page')) || 1)
            } else {
                categoryIdSet(categoryDataShow[0].value.toString())
            }
        }
    }, [routeLocation.search, categoryDataShow])

    const AxiosProps = useRef({
        method: 'post',
        url: '/Document/Search',
        loading: true,
    })
    const [{ data, loading, error, code }, getListExecute] = useAxios(AxiosProps.current)
    const listDataShowMemo = useMemo<any[]>(() => {
        if (data && data.items) {
            return data.items
        } else {
            return []
        }
    }, [data])
    const listDataTotal = useMemo(() => data?.totalCount || 0, [data])

    let keywordInit = ''
    if (routeLocation.search) {
        keywordInit = getUrlQuery(routeLocation.search, 'keyword') || ''
    }
    const [searchStr, searchStrSet] = useState(keywordInit)
    useEffect(() => {
        if (routeLocation.search) {
            const _page = getUrlQuery(routeLocation.search, 'page')
            if (_page) {
                pageNumberSet(Number(_page))
            }

            const _keyword = getUrlQuery(routeLocation.search, 'keyword')
            if (_keyword) {
                searchStrSet(_keyword)
            }
        }
    }, [])
    useEffect(() => {
        if (categoryId) {
            getListExecute({
                skipCount: (pageNumber - 1) * maxResultCount,
                maxResultCount: maxResultCount,
                type: Number(categoryId),
                keyword: searchStr,
            })
        }
    }, [categoryId, pageNumber, searchStr])

    return (
        <CusWrapper breadcrumb={[{ breadcrumbName: '表單下載' }]}>
            <CusSection>
                <Row style={{ marginBottom: breakpointContext?.isDesktop ? '30px' : '20px' }}>
                    <Col>
                        <h1>表單下載</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: breakpointContext?.isDesktop ? '60px' : '30px' }}>
                    <Col span={breakpointContext?.isDesktop ? 8 : 24}>
                        <Input.Search
                            id={scrollToMain.id}
                            placeholder='搜尋您要查找的表單'
                            enterButton
                            allowClear
                            defaultValue={searchStr}
                            onSearch={_val => {
                                searchStrSet(_val)
                                pageNumberSet(1)
                                const urlSearchStr = changeURLQuery(routeLocation.search, 'page', 1)
                                nav({ search: changeURLQuery(urlSearchStr, 'keyword', _val) })
                            }}
                        ></Input.Search>
                    </Col>
                </Row>
                <Row>
                    <div
                        style={{
                            overflowX: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '10px',
                            marginLeft: '-20px',
                            marginRight: '-20px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}
                    >
                        {renderCategory()}
                    </div>
                </Row>
                <Spin spinning={loading}>
                    {code ? (
                        <>
                            <Row style={{ marginBottom: '60px' }}>
                                <Col role={'list'} span={24}>
                                    {listDataShowMemo.length === 0 && <EmptyData title='查無資料' />}
                                    {listDataShowMemo.map((_item, index) => {
                                        return (
                                            <DownloadItem
                                                key={`${index}-${_item.documentName}`}
                                                isFirst={index === 0}
                                                documentName={_item.documentName}
                                                version={_item.version}
                                                pdfUrl={_item.pdfUrl}
                                                docUrl={_item.docUrl}
                                                odtUrl={_item.odtUrl}
                                            />
                                        )
                                    })}
                                </Col>
                            </Row>
                            {listDataShowMemo.length > 0 && (
                                <Row justify={'center'}>
                                    <Col>
                                        <Pagination
                                            showLessItems={isMobile ? true : false}
                                            current={pageNumber}
                                            pageSize={maxResultCount}
                                            showSizeChanger={false}
                                            total={listDataTotal}
                                            onChange={_page => {
                                                pageNumberSet(_page)
                                                nav({ search: changeURLQuery(routeLocation.search, 'page', _page) })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    ) : null}
                </Spin>
            </CusSection>
        </CusWrapper>
    )
}

export default Download
