import React, { useState, useMemo, useEffect, useRef } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Collapse, Space, Button } from 'antd'
import { CusSteps } from 'src/components'
import { formatEditorContentHTML } from 'src/utils'
const { Panel } = Collapse

type Item = { title: string; content: string }

type Props = {
    items: Item[]
    stepsTheme?: 'style3' | 'default'
    onStepChange?: any
    step?: number
}

const Header = (props: { index: number; item: Item }) => {
    const { index, item } = props
    return (
        <h3 id={`cus-steps-collapse-header-${index}`} className='u-scroll-margin-top'>
            <span className='step-index'>{`step ${index}`}</span>
            <span className='step-title'>{item.title}</span>
        </h3>
    )
}

const StepsCollapse = (props: Props) => {
    const { items = [], stepsTheme = 'default', step: active = 0, onStepChange } = props
    const [step, setStep] = useState(active)
    const collapseRef = useRef(null)

    const steps = useMemo(() => {
        if (Array.isArray(items)) {
            return items.map((row: Item, index: number) => ({ label: row.title, anchorLink: `cus-steps-collapse-header-${index + 1}` }))
        } else {
            return []
        }
    }, [items])

    const [expandIconPosition, setExpandIconPosition] = useState<any>('end')

    const handleCollapseChange = (key: string | string[]) => {}

    const genExtra = (data: any) => {
        return <Button type='primary' icon={<DownOutlined />}>{`展開更${data.isActive ? '少' : '多'}`}</Button>
    }

    const handleClick = (data: any, index: number) => {
        if (typeof data === 'object' && data?.anchorLink) {
            setStep(index + 1)
            if (onStepChange) onStepChange(index + 1)
            const el = document.querySelector(`#${data?.anchorLink}`)
            if (!el) return
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }

    useEffect(() => {
        setStep(active)
    }, [active])

    return (
        <Space className='cus-steps-collapse-container' size={30} direction='vertical' style={{ width: '100%' }}>
            <CusSteps value={step} items={steps} onClick={handleClick} theme={stepsTheme} />
            <Collapse
                ghost
                className={`cus-steps-collapse cus-editor-content is-theme-${stepsTheme}`}
                defaultActiveKey={['0']}
                expandIcon={genExtra}
                onChange={handleCollapseChange}
                expandIconPosition={expandIconPosition}
            >
                {Array.isArray(items) &&
                    items.map((item: Item, index: number) => (
                        <Panel header={<Header index={index + 1} item={item} />} key={index} forceRender>
                            <div dangerouslySetInnerHTML={{ __html: formatEditorContentHTML(item.content) }}></div>
                        </Panel>
                    ))}
            </Collapse>
        </Space>
    )
}

export default StepsCollapse
