import React, { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
type Props = {
    children: any
    href?: string
    to?: any
    type?: 'button' | ''
    theme?: 'primary' | 'default' | 'gray' | 'white' | 'green' | 'white-green'
    icon?: React.ReactNode
    circle?: boolean
    arrow?: 'right' | 'left'
    iconPosition?: 'left' | 'right'
    iconSize?: number
    noBreak?: boolean
    style?: any
    target?: string
    onClick?: any
    size?: 'small' | 'normal'
    rel?: string
    iconSpace?: number
    underline?: boolean
}

const Link = (props: Props) => {
    const {
        children,
        href,
        target = '_blank',
        to,
        type = '',
        theme = '',
        icon,
        circle = false,
        arrow,
        iconPosition = 'left',
        noBreak = false,
        style = {},
        onClick = () => {},
        size,
        rel = '',
        iconSpace = 0,
        underline = false,
    } = props
    const className = useMemo(() => {
        let payload = 'cus-link'

        if (type) payload += ` is-${type}`
        if (theme) payload += ` is-${theme}`
        if (circle) payload += ` is-circle`
        if (arrow) payload += ` is-arrow-${arrow}`
        if (noBreak) payload += ` is-no-break`
        if (size) payload += ` is-${size}`
        if (underline) payload += ` is-underline`

        return payload
    }, [type, theme, circle, arrow, noBreak, size, underline])

    const myIconSpace = useMemo(() => {
        if (icon && iconPosition === 'left') return { marginRight: iconSpace }
        if (icon && iconPosition === 'right') return { marginLeft: iconSpace }
        return {}
    }, [iconSpace])

    if (href)
        return (
            <a className={className} href={href} style={style} target={target} onClick={onClick} rel={target === '_blank' ? 'noreferrer' : rel}>
                {icon && iconPosition === 'left' && (
                    <span className='cus-link-icon is-left' style={{ ...myIconSpace }}>
                        {icon}
                    </span>
                )}
                {arrow === 'left' && <LeftOutlined />}
                {children}
                {arrow === 'right' && <RightOutlined />}
                {icon && iconPosition === 'right' && (
                    <span className='cus-link-icon is-right' style={{ ...myIconSpace }}>
                        {icon}
                    </span>
                )}
            </a>
        )

    return (
        <RouterLink className={className} to={to} style={style} onClick={onClick}>
            {icon && iconPosition === 'left' && (
                <span className='cus-link-icon is-left' style={{ ...myIconSpace }}>
                    {icon}
                </span>
            )}
            {arrow === 'left' && <LeftOutlined />}
            {children}
            {arrow === 'right' && <RightOutlined />}
            {icon && iconPosition === 'right' && (
                <span className='cus-link-icon is-right' style={{ ...myIconSpace }}>
                    {icon}
                </span>
            )}
        </RouterLink>
    )
}

export default Link
