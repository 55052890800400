import React from 'react'
import { Row, Col, Tooltip, Checkbox } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

type Props = {
    title?: string
    items?: { label: string; index: string | number; to?: any; tip?: string; note?: string; id?: number }[]
    disabledItems?: string[]
    checkedItems?: CheckboxValueType[]
    showCheckbox?: boolean
}

const Card = (props: Props) => {
    const { title, items = [], disabledItems = [], checkedItems = [], showCheckbox = true } = props

    const handleLinkClick = function (e: any) {
        if (checkedItems.length) {
            e.preventDefault()
            e.target.closest('.cus-business-item').querySelector('.ant-checkbox-input')?.click()
        }
    }

    return (
        <div className='cus-business-items'>
            {title && <h3>{title}</h3>}

            <Row gutter={[30, 30]}>
                {items.map((item: any, index: number) => (
                    <Col md={8} sm={12} xs={24} key={index}>
                        <div className={`cus-business-item ${disabledItems.includes(item.label) ? 'is-disabled' : ''}`}>
                            <Link to={item.to} onClick={handleLinkClick}>
                                <div>
                                    <span className='item-index'>{item.index}</span>
                                    <p>
                                        {item.label}
                                        {item.note && <span className='item-note'>{` (${item.note})`}</span>}
                                    </p>
                                    {item?.tip && (
                                        <Tooltip placement='bottomLeft' title={item.tip} overlayClassName='cus-business-item-tip' color='#e9f9ff'>
                                            <ExclamationCircleOutlined style={{ color: '#202020', fontSize: 18 }} />
                                        </Tooltip>
                                    )}
                                </div>
                                {showCheckbox && <Checkbox value={item.id} onClick={e => e.preventDefault()} />}
                            </Link>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
export default Card
