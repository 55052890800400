export function getUrlQuery(url: string, key: string) {
    if (!url.includes('?')) {
        return null
    }
    const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i')
    const resultList = url.split('?')[1].match(reg)
    return resultList ? decodeURIComponent(resultList[2]) : null
}

export function changeURLQuery(url: string, key: string, value: string | number) {
    const pattern = key + '=([^&]*)'
    const replaceText = key + '=' + value
    if (url.match(pattern)) {
        let tmp = '/(' + key + '=)([^&]*)/gi'
        tmp = url.replace(eval(tmp), replaceText)
        return tmp
    } else {
        if (url.match('[?]')) {
            return url + '&' + replaceText
        } else {
            return url + '?' + replaceText
        }
    }
}

export function setURLQuery(paramsString: string, setParams: any = {}) {
    const searchParams = new URLSearchParams(paramsString)
    Object.keys(setParams).forEach((key: string) => {
        searchParams.set(key, setParams[key])
    })
    return searchParams.toString()
}
