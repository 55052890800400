import axios from 'axios'
// import store from '@/store'
import utils from './utils'
import { v4 as uuidv4 } from 'uuid'

const instance = axios.create({
    baseURL: '/api/api/',
    timeout: 60000,
    headers: {},
})
// 請求攔截
instance.interceptors.request.use(
    (request: any) => {
        // utils.loadingHandler.on()
        const req = {
            ...utils.fixIECache(request),
            // ...utils.Authorization(request),
        }
        req.headers['x-request-id'] = uuidv4()
        return req
    },
    err => {
        return Promise.reject(err)
    }
)

// 回應攔截

const handleResponse = async (response: any) => {
    // utils.loadingHandler.off()
    const { errorCode, errorMessage } = (await utils.errorHandler(response)) as {
        errorCode: string | number
        errorMessage: undefined | string
    }
    const data = response.data && Object.hasOwnProperty.call(response.data, 'data') ? response.data.data : response.data
    return response
}

const handleError = async (error: any) => {
    if (error.response || error.message === 'Network Error') {
        const res = error.response ? error.response : { status: 'network', message: error.message }
        // utils.loadingHandler.off()
        await utils.errorHandler(res)
    }
    return Promise.reject({ data: error?.response?.data, status: error.response.status, message: error.response.message })
}

instance.interceptors.response.use(handleResponse, handleError)
// instance.interceptors.response.use(
//     (response: any) => {
//         // utils.loadingHandler.off()
//         const { errorCode, errorMessage } = utils.errorHandler(response) as {
//             errorCode: string | number
//             errorMessage: undefined | string
//         }
//         const data =
//             response.data && Object.hasOwnProperty.call(response.data, 'data')
//                 ? response.data.data
//                 : response.data
//         return [data, errorCode, errorMessage]
//     },
//     (error) => {
//         if (error.response || error.message === 'Network Error') {
//             const res = error.response
//                 ? error.response
//                 : { status: 'network', message: error.message }
//             utils.loadingHandler.off()
//             utils.errorHandler(res)
//         }
//         return Promise.reject(error.message)
//     }
// )

export default instance
