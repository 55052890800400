import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CusWrapper, CusSection, CusBreadcrumb, CusLink, CusSteps, CusStepsCollapse } from 'src/components'
import { SectionSignHeaderLinks } from 'src/sections'
import { matchRoutes, useParams, useSearchParams, useLocation } from 'react-router-dom'
import { Space, Spin } from 'antd'
import { ReactComponent as FingerSvg } from 'src/assets/images/finger.svg'
import { CompanyCategoryNameByIdEnum, SignTypeNameByIdEnum } from 'src/@types/Company'
import useAxios from 'src/hook/useAxios'
import { routesConfig } from 'src/router/routes.config'
import paths from 'src/router/paths.config'

const headerLinks = [
    { label: '申請流程', to: '?tab=flow' },
    // { label: '應備文件', to: '?tab=document' },
    // { label: '注意事項', to: '?tab=note' },
    { label: '常見問題', to: '/qna' },
    // { label: '相關法令', to: '?tab=decree' },
    // { label: '雲端登記平台', href: 'https://serv.gcis.nat.gov.tw/cfm/user/login/main.do' },
]

const Page = () => {
    const { id, category = '', action, name = '', type = '' } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const myRoute = matchRoutes(routesConfig, location) as any
    const tab = searchParams.get('tab')
    const [isStepsContent, setIsStepsContent] = useState(true)
    const title = name
    const breadcrumb = useRef([
        {
            breadcrumbName: name,
        },
    ])
    const back = `/transcription`

    const dataFormat = (data: any) => {
        if (tab === 'flow' || !tab) {
            const payload: any = []
            if (data && Array.isArray(data.stepContents)) {
                data.stepContents.forEach((row: { content: string; step: number; title: string }) => {
                    payload.push(row)
                })
            }
            return payload
        } else {
            if (data?.content) return data.content
            return ''
        }
    }

    const api = {
        url: '/CheckoutCopy/GetStep',
        format: dataFormat,
        wait: true,
        loading: false,
    }
    const [{ data, loading, error }, refetch] = useAxios(api)
    const [linkActive, setLinkActive] = useState(0)

    useEffect(() => {
        if (tab === 'flow' || !tab) {
            setIsStepsContent(true)
            api.url = '/Registration/GetStep'
            setLinkActive(1)
        }
        if (tab === 'document') {
            setIsStepsContent(false)
            api.url = '/Registration/GetRequiredDocument'
            setLinkActive(2)
        }
        if (tab === 'note') {
            setIsStepsContent(false)
            api.url = '/Registration/GetAttentionMatter'
            setLinkActive(3)
        }
        if (tab === 'decree') {
            setIsStepsContent(false)
            api.url = '/Registration/GetRelevantLaw'
            setLinkActive(5)
        }

        refetch({ id })
    }, [searchParams, useSearchParams])
    return (
        <CusWrapper hideBreadcrumb metaTitle={title}>
            <CusSection backgroundImage={{ desktop: '/images/banner-business-info.png', mobile: { url: '/images/banner-business-info-mobile.png', position: 'center bottom' } }} height={350}>
                <CusBreadcrumb items={breadcrumb.current} />
                <h1>{title}</h1>
                <Space size={20}>
                    <CusLink type='button' theme='white-green' to={back}>
                        返回前頁
                    </CusLink>
                    <CusLink type='button' href={paths.SIGN_ONLINE} theme='green' icon={<FingerSvg style={{ marginBottom: -5 }} />} iconPosition='right'>
                        線上申辦規費減免！
                    </CusLink>
                </Space>
            </CusSection>
            <SectionSignHeaderLinks active={linkActive} items={headerLinks} type='registration' data={{ id }} />
            <CusSection space={30}>
                <Spin spinning={loading}>
                    {loading ? <></> : isStepsContent ? <CusStepsCollapse items={data || []} /> : <div className='cus-editor-content' dangerouslySetInnerHTML={{ __html: data || '' }}></div>}
                </Spin>
            </CusSection>
        </CusWrapper>
    )
}

export default Page
