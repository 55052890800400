import errorHandler from './errorHandler'
// import loadingHandler from './loadingHandler'
// import { getToken } from 'src/utils/jwt'
const utils = {
    fixIECache(request: any) {
        // 解决IE下浏览器一直拿缓存的问题，加时间戳处理
        if (request.method) {
            request.params = {
                t: Date.parse(new Date() as any),
                ...request.params,
            }
        }
        return request
    },
    errorHandler,
    // loadingHandler,
    Authorization(request: any) {
        // const auth = getToken()
        // if (auth) {
        //     request.headers.Authorization = auth
        // }
        return request
    },
}

export default utils
