import React from 'react'
import { createHashRouter } from 'react-router-dom'
import { routesConfig } from './routes.config'
import Layout from 'src/components/Layout'

const router = createHashRouter([
    {
        element: <Layout />,
        id: 'layout',
        index: false,
        children: routesConfig,
    },
])
export default router
