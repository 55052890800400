import { Input, message, Space } from 'antd'
// import { ReactComponent as IconRightBtn } from 'src/assets/images/btn-white-right.svg'
import { ReactComponent as IconBannerFromRightBtn } from 'src/assets/images/home-banner-from-right-btn.svg'
import { ReactComponent as IconBtnBlue } from 'src/assets/images/btn-blue.svg'
import { ReactComponent as IconBtnBlueLight } from 'src/assets/images/btn-blue-light.svg'
import { ReactComponent as IconBtnMinusBlueLight } from 'src/assets/images/btn-minus-blue-light.svg'
import { ReactComponent as IconArrowRight } from 'src/assets/images/arrow-right.svg'
import { ReactComponent as IconCheckGreen } from 'src/assets/images/check-green.svg'
import { ReactComponent as IconBtnGreen } from 'src/assets/images/btn-green.svg'
import { ReactComponent as IconFBox2Icon1 } from 'src/assets/images/home-forth-box2-icon1.svg'
import { ReactComponent as IconFBox2Icon2 } from 'src/assets/images/home-forth-box2-icon2.svg'
import { ReactComponent as IconCheckBlue } from 'src/assets/images/check-blue.svg'

import { CusWrapper, CusLink, EmptyData, QNAItem } from 'src/components'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import useAxios from 'src/hook/useAxios'
import paths from 'src/router/paths.config'
import BreakpointContext from 'src/context/BreakpointContext'
import { scrollToMain } from 'src/utils'

const Home = () => {
    const breakpointContext = useContext(BreakpointContext)
    const nav = useNavigate()

    function renderBanner() {
        return (
            <div className='home-banner'>
                <h1 className='home-banner-title'>公司、商業登記主題網</h1>
                <div className='home-banner-sub-title'>為您提供更便捷的服務</div>

                <div className='home-banner-from'>
                    <div style={{ width: '300px' }}>
                        <Input.Search
                            placeholder='請輸入您要查找的資料'
                            enterButton
                            allowClear
                            onSearch={_val => {
                                if (_val) {
                                    nav(`/full-search?keyword=${_val}`)
                                } else {
                                    message.error('請輸入您要查找的資料')
                                }
                            }}
                        />
                    </div>
                    <a href={paths.SIGN_ONLINE} target={'_blank'} rel='noreferrer' className='home-banner-from-right-btn'>
                        <span>線上申辦規費減免！</span>
                        <IconBannerFromRightBtn />
                    </a>
                </div>

                {/* <div className='home-banner-right-btn-wrapper'>
                    <div role={'button'} className='home-banner-right-btn'>
                        <span>叫 號 查 詢</span>
                        <IconRightBtn />
                    </div>
                    <div></div>
                </div> */}
            </div>
        )
    }

    const [showSecondContent, showSecondContentSet] = useState<('1' | '2' | '3' | '4')[]>([])
    function renderSecondBox() {
        return (
            <div className='home-second'>
                <div className='home-second-bg'>
                    <div className='home-second-bg2'></div>
                </div>
                <div className='home-second-box1-wrapper'>
                    <RouterLink to={'/teach'} className='home-second-box1'>
                        <div className='home-second-box1-content' id={scrollToMain.id}>
                            <div className='home-second-box1-text'>
                                <div>申辦教學：</div>
                                <div>我該怎麼開始呢？</div>
                            </div>
                            <div className='home-second-box1-icon bg1'>
                                <IconBtnBlue />
                            </div>
                        </div>
                    </RouterLink>
                    <div className='home-second-box1-wrapper2'>
                        <RouterLink to={'/sign?step1=1'} className='home-second-box1'>
                            <div className='home-second-box1-content'>
                                <div className='home-second-box1-text'>
                                    <div>公司登記</div>
                                </div>
                                <div className='home-second-box1-icon bg2'>
                                    <IconBtnBlue />
                                </div>
                            </div>
                        </RouterLink>
                        <RouterLink to={'/sign?step1=2'} className='home-second-box1'>
                            <div className='home-second-box1-content'>
                                <div className='home-second-box1-text'>
                                    <div>商業登記</div>
                                </div>
                                <div className='home-second-box1-icon bg3'>
                                    <IconBtnBlue />
                                </div>
                            </div>
                        </RouterLink>
                    </div>
                    <RouterLink to={'/transcription'} className='home-second-box1'>
                        <div className='home-second-box1-content'>
                            <div className='home-second-box1-text'>
                                <div>查閱抄錄專區</div>
                            </div>
                            <div className='home-second-box1-icon bg4'>
                                <IconBtnBlue />
                            </div>
                        </div>
                    </RouterLink>
                </div>
                <h2 className='home-second-title'>第一次申辦就上手</h2>
                <div className='home-second-content'>
                    <div className={`home-second-content-box ${showSecondContent.includes('1') ? 'active' : ''}`}>
                        <div className='home-second-content-box-icon'></div>
                        <div className='home-second-content-header'>
                            <h4 onClick={() => showSecondContentSet([...showSecondContent, '1'])}>公司登記跟商業登記差別？</h4>
                            {showSecondContent.includes('1') ? (
                                <IconBtnMinusBlueLight
                                    role={'button'}
                                    aria-label='顯示詳情'
                                    className='home-second-content-btn'
                                    onClick={() => {
                                        showSecondContent.splice(showSecondContent.indexOf('1'), 1)
                                        showSecondContentSet([...showSecondContent])
                                    }}
                                />
                            ) : (
                                <IconBtnBlueLight role={'button'} aria-label='隱藏詳情' className='home-second-content-btn' onClick={() => showSecondContentSet([...showSecondContent, '1'])} />
                            )}
                        </div>
                        {showSecondContent.includes('1') && (
                            <div role={'alert'} className='home-second-content-text'>
                                <p>
                                    <strong>公司登記：</strong>您想要做一個全國性的營利事業，商品會賣到全台灣甚至國外，有大量的股東人數，則適合公司登記。
                                </p>
                                <p>
                                    <strong>商業登記：</strong>您想在當地做一個小本生意、兼職、接業配、開工作室等等，則適合商業登記。
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={`home-second-content-box ${showSecondContent.includes('2') ? 'active' : ''}`}>
                        <div className='home-second-content-box-icon'></div>
                        <div className='home-second-content-header'>
                            <h4 onClick={() => showSecondContentSet([...showSecondContent, '2'])}>我該怎麼開始呢？</h4>
                            {showSecondContent.includes('2') ? (
                                <IconBtnMinusBlueLight
                                    role={'button'}
                                    aria-label='顯示詳情'
                                    className='home-second-content-btn'
                                    onClick={() => {
                                        showSecondContent.splice(showSecondContent.indexOf('2'), 1)
                                        showSecondContentSet([...showSecondContent])
                                    }}
                                />
                            ) : (
                                <IconBtnBlueLight role={'button'} aria-label='隱藏詳情' className='home-second-content-btn' onClick={() => showSecondContentSet([...showSecondContent, '2'])} />
                            )}
                        </div>
                        {showSecondContent.includes('2') && (
                            <div role={'alert'} className='home-second-content-text'>
                                <p>
                                    如果要設立一間公司的話……
                                    <CusLink to={'/sign?step1=1'} underline>
                                        請點我
                                    </CusLink>{' '}
                                    查看詳細步驟
                                </p>
                                <p>
                                    如果要設立一間商號的話……
                                    <CusLink to={'/sign?step1=2'} underline>
                                        請點我
                                    </CusLink>{' '}
                                    查看詳細步驟
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={`home-second-content-box ${showSecondContent.includes('3') ? 'active' : ''}`}>
                        <div className='home-second-content-box-icon'></div>
                        <div className='home-second-content-header'>
                            <h4 onClick={() => showSecondContentSet([...showSecondContent, '3'])}>我有需要辦理工商登記嗎？</h4>
                            {showSecondContent.includes('3') ? (
                                <IconBtnMinusBlueLight
                                    role={'button'}
                                    aria-label='顯示詳情'
                                    className='home-second-content-btn'
                                    onClick={() => {
                                        showSecondContent.splice(showSecondContent.indexOf('3'), 1)
                                        showSecondContentSet([...showSecondContent])
                                    }}
                                />
                            ) : (
                                <IconBtnBlueLight role={'button'} aria-label='隱藏詳情' className='home-second-content-btn' onClick={() => showSecondContentSet([...showSecondContent, '3'])} />
                            )}
                        </div>
                        {showSecondContent.includes('3') && (
                            <div role={'alert'} className='home-second-content-text'>
                                <p>
                                    <strong>
                                        <u>除了以下列出業者其餘業者皆須辦理工商登記： </u>
                                    </strong>
                                </p>
                                <ol>
                                    <li>攤販</li>
                                    <li>家庭農、林、漁、牧業者</li>
                                    <li>家庭手工業者</li>
                                    <li>民宿經營者</li>
                                    <li>每月銷售額未達營業稅起徵點者</li>
                                </ol>
                                <p>前項第二款及第三款所定小規模商業，以自任操作或雖僱用員工而仍以自己操作為主者為限。</p>
                            </div>
                        )}
                    </div>
                    <div className={`home-second-content-box ${showSecondContent.includes('4') ? 'active' : ''}`}>
                        <div className='home-second-content-box-icon'></div>
                        <div className='home-second-content-header'>
                            <h4 onClick={() => showSecondContentSet([...showSecondContent, '4'])}>需要準備的資料有哪些？</h4>
                            {showSecondContent.includes('4') ? (
                                <IconBtnMinusBlueLight
                                    role={'button'}
                                    aria-label='顯示詳情'
                                    className='home-second-content-btn'
                                    onClick={() => {
                                        showSecondContent.splice(showSecondContent.indexOf('4'), 1)
                                        showSecondContentSet([...showSecondContent])
                                    }}
                                />
                            ) : (
                                <IconBtnBlueLight role={'button'} aria-label='隱藏詳情' className='home-second-content-btn' onClick={() => showSecondContentSet([...showSecondContent, '4'])} />
                            )}
                        </div>
                        {showSecondContent.includes('4') && (
                            <div role={'alert'} className='home-second-content-text'>
                                <p>
                                    有限公司設立應備文件{' '}
                                    <CusLink to={'/sign?step1=1&step2=1&step3=1'} underline>
                                        請點我
                                    </CusLink>
                                </p>
                                <p>
                                    股份有限公司設立應備文件{' '}
                                    <CusLink to={'/sign?step1=1&step2=2&step3=1'} underline>
                                        請點我
                                    </CusLink>
                                </p>
                                <p>
                                    獨資商號設立應備文件{' '}
                                    <CusLink to={'/sign?step1=2&step2=4&step3=1'} underline>
                                        請點我
                                    </CusLink>
                                </p>
                                <p>
                                    合夥商號設立應備文件{' '}
                                    <CusLink to={'/sign?step1=2&step2=5&step3=1'} underline>
                                        請點我
                                    </CusLink>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    function renderThirdBoxTop() {
        return (
            <div className='home-third-top'>
                <div className='home-third-top-bg2'></div>
                <div className='home-second-btn'>
                    <CusLink type='button' to={'/teach'}>
                        申辦教學，請點我！
                    </CusLink>
                </div>
            </div>
        )
    }

    const [showPopularDoc, showPopularDocSet] = useState<number>(1)
    const RegistrationsAxiosProps = useRef({
        url: '/Home/GetRegistrations',
    })
    const [{ data: RegistrationsData }] = useAxios(RegistrationsAxiosProps.current)
    const RegistrationsBtnShow = useMemo<{ groupTitle: string; groupType: number }[]>(() => {
        if (RegistrationsData) {
            return RegistrationsData
        }
        return []
    }, [RegistrationsData])
    interface RegistrationsShowItem {
        actionType: number
        companyCategory: number
        name: string
        id: number
    }
    const RegistrationsShow = useMemo<RegistrationsShowItem[][]>(() => {
        if (RegistrationsData) {
            const _showData = RegistrationsData.find((_item: any) => _item.groupType === showPopularDoc)
            if (_showData && _showData.documents) {
                const _showList: RegistrationsShowItem[][] = []
                _showData.documents.forEach((element: any, index: number) => {
                    if (!_showList[~~(index / 2)]) {
                        _showList[~~(index / 2)] = [element]
                    } else {
                        _showList[~~(index / 2)][index % 2] = element
                    }
                })
                return _showList
            } else {
                return []
            }
        }
        return []
    }, [RegistrationsData, showPopularDoc])

    const formatName = (text: string) => {
        return text.split('-').slice(1).join('-')
    }

    const [showTranscriptionZone, showTranscriptionZoneSet] = useState<number>(1)
    const [{ data: CheckoutCopyData }, CheckoutCopyExecute] = useAxios({ url: 'Home/GetCheckOutcopys', wait: true })
    useEffect(() => {
        CheckoutCopyExecute({ type: showTranscriptionZone })
    }, [showTranscriptionZone])
    const TranscriptionZoneShow = useMemo<{ title: string; registrationId: number; filePath: string }[]>(() => {
        if (CheckoutCopyData) {
            return CheckoutCopyData
        }

        return []
    }, [CheckoutCopyData])

    function renderThirdBox() {
        return (
            <div className='home-third'>
                <div className='home-third-box'>
                    <div className='home-third-wrapper' style={{ paddingRight: '60px' }}>
                        <h2 className='home-third-title'>熱門文件</h2>
                        <Space size={36} role={'tablist'}>
                            {RegistrationsBtnShow.map(_item => (
                                <div
                                    role={'tab'}
                                    key={_item.groupType}
                                    className={`cus-link is-button is-circle ${showPopularDoc === _item.groupType ? 'is-primary' : 'is-gray'}`}
                                    onClick={() => {
                                        showPopularDocSet(_item.groupType)
                                    }}
                                >
                                    {_item.groupTitle}
                                </div>
                            ))}
                        </Space>
                        <div role={'tabpanel'} className='home-third-content'>
                            {RegistrationsShow.map((_item, _index) => {
                                return (
                                    <div className='home-third-content-item-wrapper' key={_index}>
                                        {_item.map((_item2, _index2) => {
                                            return (
                                                <RouterLink
                                                    to={`/sign/${showPopularDoc}/${_item2.companyCategory}/${_item2.actionType}/${_item2.id}/${formatName(_item2.name)}`}
                                                    className='home-third-content-item'
                                                    key={`${_index}-${_index2}`}
                                                >
                                                    <span>{_item2.name}</span>
                                                    <div className='icon-arrow-right-wrapper'>
                                                        <IconArrowRight />
                                                    </div>
                                                </RouterLink>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            {RegistrationsShow.length === 0 && <EmptyData size='small' title='查無文件' />}
                        </div>
                        <div className='home-third-link'>
                            <CusLink to={`/sign/all/${showPopularDoc}`}>
                                <span>查看全部文件</span>
                                <div className='icon-arrow-right-wrapper'>
                                    <IconArrowRight />
                                </div>
                            </CusLink>
                        </div>
                    </div>
                </div>
                <div className='home-third-box'>
                    <div className='home-third-wrapper' style={{ paddingLeft: '60px' }}>
                        <h2 className='home-third-title'>抄錄專區</h2>
                        <Space size={36} role={'tablist'}>
                            {[
                                { groupType: 1, groupTitle: '公司登記' },
                                { groupType: 2, groupTitle: '商業登記' },
                            ].map(_item => {
                                return (
                                    <div
                                        role={'tab'}
                                        key={_item.groupType}
                                        className={`cus-link is-button is-circle ${showTranscriptionZone === _item.groupType ? 'is-primary' : 'is-gray'}`}
                                        onClick={() => {
                                            showTranscriptionZoneSet(_item.groupType)
                                        }}
                                    >
                                        {_item.groupTitle}
                                    </div>
                                )
                            })}
                        </Space>
                        <div role={'tabpanel'} className='home-third-content' style={{ marginBottom: '30px' }}>
                            {TranscriptionZoneShow.map((_item, _index) =>
                                breakpointContext?.isDesktop ? (
                                    <div className='home-third-content-item' key={_index}>
                                        <span>{_item.title}</span>
                                        <div className='home-third-link'>
                                            <CusLink to={`/transcription/${_item.registrationId}/${_item.title}`}>
                                                <span>文件下載</span>
                                                <div className='icon-arrow-right-wrapper'>
                                                    <IconArrowRight />
                                                </div>
                                            </CusLink>
                                            <a className='cus-link' href={_item.filePath} download={`${_item.title}`}>
                                                <span>範例下載</span>
                                                <div className='icon-arrow-right-wrapper'>
                                                    <IconArrowRight />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <RouterLink to={`/transcription/${_item.registrationId}/${_item.title}`} className='home-third-content-item' key={_index}>
                                        <span>{_item.title}</span>
                                        <div className='icon-arrow-right-wrapper'>
                                            <IconArrowRight />
                                        </div>
                                    </RouterLink>
                                )
                            )}
                            {TranscriptionZoneShow.length === 0 && <EmptyData size='small' title='查無文件' />}
                        </div>
                        <div className='home-third-link'>
                            <div style={{ display: 'flex' }}>
                                不知道什麼是抄錄嗎？來看看
                                <CusLink to={`/transcription`}>
                                    <span style={{ textDecoration: 'underline', marginLeft: '4px' }}>抄錄說明</span>
                                </CusLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderForthBox() {
        return (
            <div className='home-forth'>
                <div className='home-forth-top-bg-wrapper'>
                    <div className='home-forth-top-bg1'></div>
                    <div className='home-forth-top-bg2'></div>
                </div>
                <h2 className='home-forth-title'>該選擇 商業登記 還是 公司登記？</h2>
                <div className='home-forth-wrapper'>
                    <div className='home-forth-box'>
                        <div className='home-forth-box1'>
                            <div className='home-forth-box1-content'>
                                <h3>商業登記</h3>
                                <div className='home-forth-box1-content-list'>
                                    <div className='home-forth-box1-content-item'>
                                        <IconCheckGreen />
                                        <span>以營利為目的，獨資或合夥之事業</span>
                                    </div>
                                    <div className='home-forth-box1-content-item'>
                                        <IconCheckGreen />
                                        <span>{'每月銷售額達營業稅起徵點\n(銷售貨物為8萬元、銷售勞務為4萬元)'}</span>
                                    </div>
                                </div>
                                <CusLink
                                    type='button'
                                    theme='white-green'
                                    icon={
                                        <div className='btn-icon'>
                                            <IconBtnGreen />
                                        </div>
                                    }
                                    iconPosition='right'
                                    to={'/sign?step1=2'}
                                >
                                    前往登記
                                </CusLink>
                            </div>
                            <img className='home-forth-box1-content-img' src={require('src/assets/images/home-forth-bg1.png')} alt='背景圖' />
                            <img className='home-forth-box1-content-img-m' src={require('src/assets/images/home-forth-bg1-m.png')} alt='背景圖手機版' />
                        </div>
                        <div className='home-forth-box2'>
                            <div className='home-forth-box2-icon-wrapper'>
                                <IconFBox2Icon1 />
                            </div>
                            <h3>商業登記懶人包</h3>
                            <RouterLink className='home-forth-box2-link' to={'/teach?type=business'}>
                                <div>獨資商業/合夥商業登記的差別</div>
                                <div>商業登記流程說明</div>
                            </RouterLink>
                        </div>
                    </div>
                    <div className='home-forth-box'>
                        <div className='home-forth-box1'>
                            <div className='home-forth-box1-content'>
                                <h3>公司登記</h3>
                                <div className='home-forth-box1-content-list'>
                                    <div className='home-forth-box1-content-item'>
                                        <IconCheckBlue />
                                        <span>營利事業 </span>
                                    </div>
                                    <div className='home-forth-box1-content-item'>
                                        <IconCheckBlue />
                                        <span>{'一人以上或有大量的股東、商品賣到全台灣甚至國外'}</span>
                                    </div>
                                    <div className='home-forth-box1-content-item'>
                                        <IconCheckBlue />
                                        <span>{'公司種類包含：有限公司、股份有限公司等'}</span>
                                    </div>
                                </div>
                                <CusLink
                                    type='button'
                                    icon={
                                        <div className='btn-icon'>
                                            <IconBtnBlue style={{ width: '24px', height: '24px' }} />
                                        </div>
                                    }
                                    iconPosition='right'
                                    to={'/sign?step1=1'}
                                >
                                    前往登記
                                </CusLink>
                            </div>
                            <img className='home-forth-box1-content-img' src={require('src/assets/images/home-forth-bg2.png')} alt='背景圖' />
                            <img className='home-forth-box1-content-img-m' src={require('src/assets/images/home-forth-bg2-m.png')} alt='背景圖手機版' />
                        </div>
                        <div className='home-forth-box2'>
                            <div className='home-forth-box2-icon-wrapper'>
                                <IconFBox2Icon2 />
                            </div>
                            <h3>公司登記懶人包</h3>
                            <RouterLink className='home-forth-box2-link' to={'/teach?type=company'}>
                                <div>有限公司/股份有限公司/有限合夥的差別</div>
                                <div>公司登記流程說明</div>
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderFifthBox() {
        return (
            <div className='home-fifth'>
                <div className='home-fifth-bg2'></div>
                <div className='home-fifth-wrapper'>
                    <h2>第一次申辦嗎？</h2>
                    <div>快來看看我們的新手申辦懶人包</div>
                    <CusLink type='button' theme='primary' to={'/teach'}>
                        查看完整懶人包
                    </CusLink>
                </div>
            </div>
        )
    }

    const QuestionsAxiosProps = useRef({
        url: '/Home/GetQuestions',
    })
    const [{ data: QuestionsData }] = useAxios(QuestionsAxiosProps.current)
    const QAShow = useMemo<{ title: string; content: string; id: number }[]>(() => {
        return QuestionsData || []
    }, [QuestionsData])

    const [showQAList, showQAListSet] = useState<number[]>([])
    function renderSixthBox() {
        return (
            <div className='home-sixth'>
                <div className='home-sixth-bg'>
                    <div className='home-sixth-bg2'></div>
                </div>
                <div className='home-sixth-wrapper'>
                    <div className='home-sixth-box'>
                        <h2 className='home-sixth-box-title'>常見問題</h2>
                        <div className='home-sixth-box-list'>
                            {QAShow.map((_item, index) => {
                                return (
                                    <QNAItem
                                        title={_item.title}
                                        content={_item.content}
                                        key={`${_item.id}-${index}`}
                                        showContent={showQAList.includes(_item.id)}
                                        isFirst={index === 0}
                                        onChangeShowContent={() => {
                                            if (showQAList.includes(_item.id)) {
                                                showQAList.splice(showQAList.indexOf(_item.id), 1)
                                                showQAListSet([...showQAList])
                                            } else {
                                                showQAListSet([...showQAList, _item.id])
                                            }
                                        }}
                                    />
                                )
                            })}
                            {QAShow.length === 0 && <EmptyData size='small' title='查無問題' />}
                        </div>
                        <div className='home-sixth-box-btn-wrapper'>
                            <CusLink type='button' theme='primary' to={'/qna'}>
                                了解更多
                            </CusLink>
                        </div>
                    </div>
                    <div className='home-sixth-box-bg'>
                        <div className='home-sixth-box-bg2'></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <CusWrapper className='home-page' style={{ padding: 0 }} hideBreadcrumb>
            {renderBanner()}
            {renderSecondBox()}
            {renderThirdBoxTop()}
            {renderThirdBox()}
            {renderForthBox()}
            {renderFifthBox()}
            {renderSixthBox()}
        </CusWrapper>
    )
}

export default Home
