export const addTableContainer = (tableEle: Element) => {
    const parent = tableEle.parentNode
    const tableContainer = document.createElement('div')
    tableContainer.className = 'cus-table-container'
    parent?.replaceChild(tableContainer, tableEle)
    tableContainer.appendChild(tableEle)
}

export const formatEditorContentHTML = (html: string) => {
    if (html) {
        const container = document.createElement('div')
        container.innerHTML = html

        const setTemplateButton = (tableEle: Element) => {
            // let templateTdIndex = -1

            const templateTdIndex = Array.from(tableEle.querySelectorAll('tr:first-of-type td')).findIndex(el => {
                return el.textContent?.includes('範例下載')
            })

            const fileTdIndex = Array.from(tableEle.querySelectorAll('tr:first-of-type td')).findIndex(el => {
                return el.textContent?.includes('檔案下載')
            })

            if (templateTdIndex > 0) {
                Array.from(tableEle.querySelectorAll(`tr td:nth-of-type(${templateTdIndex + 1})`)).forEach(el => {
                    el.classList.add('is-template')
                    el.querySelectorAll('a').forEach((aEle: any) => {
                        aEle?.setAttribute('download', '')
                        aEle?.removeAttribute('target')
                    })
                    return el
                })
            }
            if (fileTdIndex > 0) {
                Array.from(tableEle.querySelectorAll(`tr td:nth-of-type(${fileTdIndex + 1})`)).forEach(el => {
                    el.classList.add('is-files')
                    el.innerHTML = el.innerHTML.replace(/nbsp;/g, '').replace(/&/g, '')
                    el.querySelectorAll('a').forEach((aEle: any) => {
                        aEle?.setAttribute('download', '')
                        aEle?.removeAttribute('target')
                    })
                    return el
                })
            }
        }
        const tableEleList = container.querySelectorAll('table')
        tableEleList.forEach((tableEle: any) => {
            tableEle.style = ''
            addTableContainer(tableEle)
            setTemplateButton(tableEle)
        })

        return container.innerHTML
    }
    return html
}

export const scrollToMain = {
    id: 'scroll-to-main',
    setFirstLink() {
        let firstLink = document.getElementById('first-link')
        if (!firstLink) {
            firstLink = document.createElement('a')
            firstLink.setAttribute('id', 'first-link')
            firstLink.setAttribute('href', 'javascript:void(0);')
            firstLink.setAttribute('ref', 'noreferrer')
            firstLink.style.opacity = '0'
            firstLink.style.position = 'absolute'
            firstLink.style.height = '0'

            document.getElementById('go-main')?.before(firstLink)
        }
    },
    init() {
        scrollToMain.setFirstLink()
        document.getElementById('first-link')?.focus()
    },
    move() {
        const target: any = document.getElementById('scroll-to-main')
        scrollToMain.setFirstLink()
        document.getElementById('first-link')?.focus()
        setTimeout(() => {
            target.focus()
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }, 100)
    },
}

export const setTableContainerMouseScroll = () => {
    const setMouseScroll = (el: any) => {
        let ox = 0
        let last_left = 0
        function mousemove(e: any) {
            e = e || window.event
            last_left = e.clientX - ox
            el.scrollLeft = ox - e.clientX
            // console.log(e.clientX, ox, el.offsetWidth, -(ox - e.clientX))
        }
        function mouseup() {
            el.onmouseup = el.onmousemove = null
            el.style.cursor = ''
        }
        if (!el.ontouchstart) {
            el.classList.add('is-desktop-scroll')
            const isMac = new RegExp(/macintosh|mac os x/i)
            if (navigator && isMac.test(navigator?.userAgent)) {
                el.onmousedown = function (e: any) {
                    // e.button 可以識別是哪個按鈕 0:左鍵; 1:中間鍵; 2: 右鍵
                    if (e.button === 1) {
                        el.onmousemove = mousemove
                        el.onmouseup = mouseup
                        el.style.cursor = 'move'
                        e = e || window.event
                        if (last_left > 0) {
                            ox = e.clientX - last_left
                        } else {
                            // console.log(el.scrollLeft)
                            ox = e.clientX - el.scrollLeft
                        }
                    }
                }
            }
        }
    }

    const timer = setInterval(() => {
        const tableContainerList = document.querySelectorAll('.cus-table-container')
        if (tableContainerList.length) {
            clearInterval(timer)
            tableContainerList.forEach((el: any) => {
                if (el.scrollWidth > el.clientWidth) {
                    setMouseScroll(el)
                }
            })
        }
    }, 500)
}
