import { Row, Col, Input, Pagination, Spin } from 'antd'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CusLink, CusSection, CusWrapper, EmptyData } from 'src/components'
import { changeURLQuery, getUrlQuery } from 'src/utils/url'
import useAxios from 'src/hook/useAxios'
import BreakpointContext from 'src/context/BreakpointContext'
import { ReactComponent as IconArrowRight } from 'src/assets/images/arrow-right.svg'
import useRWD from 'src/hook/useRWD'

export default function FullSearch() {
    const breakpointContext = useContext(BreakpointContext)
    const nav = useNavigate()
    const { isMobile } = useRWD()

    const routeLocation = useLocation()

    const AxiosProps = useRef({
        method: 'post',
        url: '/FullSearch/FullSearch',
        loading: false,
    })
    const [{ data, loading, code }, getListExecute] = useAxios(AxiosProps.current)
    const listDataShowMemo = useMemo<any[]>(() => {
        if (data && data.items) {
            return data.items
        } else {
            return []
        }
    }, [data])
    const listDataTotal = useMemo(() => data?.totalCount || 0, [data])

    let keywordInit = ''
    if (routeLocation.search) {
        keywordInit = getUrlQuery(routeLocation.search, 'keyword') || ''
    }
    const [searchStr, searchStrSet] = useState(keywordInit)

    const maxResultCount = 10

    let pageInit = ''
    if (routeLocation.search) {
        pageInit = getUrlQuery(routeLocation.search, 'page') || '1'
    }
    const [pageNumber, pageNumberSet] = useState(Number(pageInit))

    useEffect(() => {
        if (searchStr) {
            getListExecute({
                skipCount: (pageNumber - 1) * maxResultCount,
                maxResultCount: maxResultCount,
                keyword: searchStr,
            })
        }
    }, [pageNumber, searchStr])

    const listDataTotalTips = `搜尋“${searchStr}“ 共 ${listDataTotal} 筆資料`

    return (
        <CusWrapper breadcrumb={[{ breadcrumbName: '全站搜尋' }]}>
            <Spin spinning={loading}>
                {code !== 0 && (
                    <CusSection className='full-search-page'>
                        <Row style={{ marginBottom: breakpointContext?.isDesktop ? '30px' : '20px' }}>
                            <Col>{listDataShowMemo.length > 0 ? <h1>全站搜尋</h1> : <h1>{listDataTotalTips}</h1>}</Col>
                        </Row>
                        {listDataShowMemo.length > 0 && (
                            <div>
                                <Row style={{ marginBottom: breakpointContext?.isDesktop ? '60px' : '30px' }}>
                                    <Col span={breakpointContext?.isDesktop ? 8 : 24}>
                                        <Input.Search
                                            placeholder='請輸入您要查找的資料'
                                            enterButton
                                            allowClear
                                            defaultValue={searchStr}
                                            onSearch={_val => {
                                                searchStrSet(_val)
                                                pageNumberSet(1)
                                                const urlSearchStr = changeURLQuery(routeLocation.search, 'page', 1)
                                                nav({ search: changeURLQuery(urlSearchStr, 'keyword', _val) })
                                            }}
                                        ></Input.Search>
                                    </Col>
                                </Row>
                                {!!searchStr && <div style={{ marginBottom: '20px' }}>{listDataTotalTips}</div>}
                            </div>
                        )}
                        <Row style={{ marginBottom: '60px' }}>
                            <Col role={'list'} span={24}>
                                {(!searchStr || listDataShowMemo.length === 0) && (
                                    <div style={{ height: '100%', position: 'relative', textAlign: 'center' }}>
                                        <EmptyData title='查無資料' />
                                        <CusLink to={'/'} type='button' theme='primary' style={{ position: 'relative', top: '-140px' }}>
                                            回首頁
                                        </CusLink>
                                    </div>
                                )}
                                {!!searchStr && (
                                    <div role={'listbox'}>
                                        {listDataShowMemo.map((_item, index) => {
                                            let link = ''
                                            if (_item.type === 1) {
                                                link = `/sign/1/${_item.companyCategory}/${_item.actionType}/${_item.id}/${_item.name}`
                                            }
                                            if (_item.type === 2) {
                                                link = `/sign/2/${_item.companyCategory}/${_item.actionType}/${_item.id}/${_item.name}`
                                            }
                                            if (_item.type === 3) {
                                                link = `/transcription/${_item.id}/${_item.name}`
                                            }
                                            return (
                                                <div role={'listitem'} key={index} className='full-search-listitem'>
                                                    <CusLink key={index} to={link}>
                                                        <h3>{_item.name}</h3>
                                                        <IconArrowRight className='full-search-listitem-icon' />
                                                    </CusLink>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {!!searchStr && listDataShowMemo.length > 0 && (
                            <Row justify={'center'}>
                                <Col>
                                    <Pagination
                                        showLessItems={isMobile ? true : false}
                                        current={pageNumber}
                                        pageSize={maxResultCount}
                                        showSizeChanger={false}
                                        total={listDataTotal}
                                        onChange={_page => {
                                            pageNumberSet(_page)
                                            nav({ search: changeURLQuery(routeLocation.search, 'page', _page) })
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                    </CusSection>
                )}
            </Spin>
        </CusWrapper>
    )
}
