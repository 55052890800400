import React from 'react'
import { CusSection, CusLink } from 'src/components'
import { Row, Col } from 'antd'
import useRWD from 'src/hook/useRWD'
import { scrollToMain } from 'src/utils'

type Link = { to: string; active?: boolean; label: string }

type Props = {
    title: string
    links: { guide: string; all: string }
    active: number
    hideLinks: boolean
}

const BusinessHeader = (props: Props) => {
    const { title, links, active, hideLinks } = props
    const { isMobile } = useRWD()
    return (
        <CusSection className='cus-section-business-header'>
            <Row justify='space-between'>
                <Col>
                    <h1 id={scrollToMain.id}>{title}</h1>
                </Col>
                {!hideLinks && (
                    <Col flex={1} xs={24}>
                        {/* FIXME: justify 使用 {xs:'space-between', sm:'end'} 時，切換會閃爍 */}
                        <Row className='row-links' gutter={{ xs: 8, sm: 36 }} justify={isMobile ? 'space-between' : 'end'}>
                            <Col>
                                <CusLink type='button' theme={active === 0 ? 'primary' : 'gray'} circle to={links.guide}>
                                    引導模式
                                </CusLink>
                            </Col>
                            <Col>
                                <CusLink type='button' theme={active === 1 ? 'primary' : 'gray'} circle to={links.all}>
                                    全部文件
                                </CusLink>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </CusSection>
    )
}

export default BusinessHeader
