import React from 'react'
import { CusSection, CusLink } from 'src/components'
import { Space, Row, Col } from 'antd'
import paths from 'src/router/paths.config'
type Props = {
    business?: boolean
}
const OtherNeed = (props: Props) => {
    const { business = false } = props
    return (
        <CusSection theme='blue' className='cus-section-other-need'>
            <h3>有其他需求嗎？我們已為你準備好</h3>
            <div className='links-container'>
                <CusLink type='button' theme='white' to={business ? paths.TEACH_BUSINESS : paths.TEACH}>
                    {business ? '商業' : '公司'}登記懶人包
                </CusLink>
                <CusLink type='button' theme='white' href={paths.CASE_PROCESS_SEARCH}>
                    案件申辦查詢
                </CusLink>
                <CusLink type='button' theme='white' to={paths.TRANSCRIPTION}>
                    查閱抄錄專區
                </CusLink>
                <CusLink type='button' theme='white' href={paths.PRE_CHECK}>
                    預查申請
                </CusLink>
            </div>
        </CusSection>
    )
}

export default OtherNeed
