import React, { createContext, useEffect, useState } from 'react'
import { TypeBreakpointContent } from 'src/@types/Breakpoint'

const BreakpointContext = createContext<TypeBreakpointContent | null>(null)

type Props = {
    children: React.ReactNode
}

export const BreakpointProvider = (props: Props) => {
    const [isMobile, setIsMobile] = useState(false)
    const [isPad, setIsPad] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)

    useEffect(() => {
        const watchBreakpoint = () => {
            const width = window.innerWidth
            if (width > 1024) {
                setIsMobile(false)
                setIsPad(false)
                setIsDesktop(true)
            } else if (width > 576) {
                setIsMobile(false)
                setIsPad(true)
                setIsDesktop(false)
            } else {
                setIsMobile(true)
                setIsPad(false)
                setIsDesktop(false)
            }
        }
        watchBreakpoint()

        window.addEventListener('resize', watchBreakpoint)

        return () => {
            window.removeEventListener('resize', watchBreakpoint)
        }
    }, [])

    return <BreakpointContext.Provider value={{ isMobile, isPad, isDesktop }}>{props.children}</BreakpointContext.Provider>
}

export default BreakpointContext
