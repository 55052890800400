import React from 'react'
import { CusWrapper, CusSection, CusLink } from 'src/components'
import { Row, Col } from 'antd'
import { ReactComponent as IconLinkOut } from 'src/assets/images/icon-link-out.svg'
import paths from 'src/router/paths.config'
const HrefLink = (props: { href?: string; children: React.ReactNode }) => {
    return (
        <CusLink href={props.href} icon={<IconLinkOut />} iconPosition='right' iconSpace={6}>
            {props.children}
        </CusLink>
    )
}

const Page = () => {
    return (
        <CusWrapper>
            <CusSection className='section-document-content'>
                <h1>網站導覽</h1>
                <h2>網站內容：</h2>
                <p>
                    本網站依無障礙網頁設計原則建置，網站的主要內容分為三大區塊：<br></br>
                    1. 上方功能區塊、2. 中央內容區塊、3.下方選單連結區塊
                </p>
                <h2>快速鍵設定：</h2>
                <p>
                    Alt+U(IE、Chrome)、Alt+Shift+U(FireFox)：上方導覽連結區，此區塊列有本網站的主要連結。<br></br>
                    Alt+C(IE、Chrome)、Alt+Shift+C(FireFox)：中間主要內容區，此區塊呈現各網頁的網頁內容。<br></br>
                    Alt+L(IE、Chrome)、Alt+Shift+L(FireFox)：左方次要連結區，此區塊列有本網站的次要連結。(首頁無此功能)<br></br>
                    Alt+Z(IE、Chrome)、Alt+Shift+Z(FireFox)：頁尾網站資訊。
                </p>
                <h2>網站架構：</h2>
                <div className='guide-list'>
                    <Row gutter={[40, 30]}>
                        <Col>
                            <h3>公司登記</h3>
                            <ul>
                                <li>
                                    <CusLink to={paths.SIGN_COMPANY_STEP2_1}>有限公司登記</CusLink>
                                </li>
                                <li>
                                    <CusLink to={paths.SIGN_COMPANY_STEP2_2}>股份有限公司登記</CusLink>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>商業登記</h3>
                            <ul>
                                <li>
                                    <CusLink to={paths.SIGN_BUSINESS_STEP2_1}>獨資商業登記</CusLink>
                                </li>
                                <li>
                                    <CusLink to={paths.SIGN_BUSINESS_STEP2_2}>合夥商業登記</CusLink>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>相關服務</h3>
                            <ul>
                                <li>
                                    <CusLink to={paths.GUIDE}>申辦教學</CusLink>
                                </li>
                                <li>
                                    <CusLink to={paths.QA}>常見問題</CusLink>
                                </li>
                                <li>
                                    <CusLink to={paths.DOWNLOAD}>表單下載</CusLink>
                                </li>
                                <li>
                                    <HrefLink href={paths.SIGN_ONLINE}>線上申辦</HrefLink>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>便民服務</h3>
                            <ul>
                                <li>
                                    <HrefLink href={paths.SIGN_COMPANY_NEED_DOCUMENT}>公司登記應備文件</HrefLink>
                                </li>
                                <li>
                                    <HrefLink href={paths.CASE_PROCESS_SEARCH}>案件進度查詢</HrefLink>
                                </li>
                                <li>
                                    <HrefLink href={paths.LAND_USE_CONTROL}>土地使用管制</HrefLink>
                                </li>
                                <li>
                                    <HrefLink href={paths.COMPANY_SING_CLOUD}>公司雲端登記平台</HrefLink>
                                </li>
                                <li>
                                    <HrefLink href={paths.FILE_COMPANY_SIGN_FLOW}>公司設立流程-EN</HrefLink>
                                </li>
                                <li>
                                    <HrefLink href={paths.FILE_BUSINESS_SIGN_FLOW}>商業設立流程-EN</HrefLink>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>其他機關</h3>
                            <ul>
                                <li>
                                    <HrefLink href={paths.EDBKCG}>高雄市經濟發展局</HrefLink>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>頁尾連結</h3>
                            <ul>
                                <li>
                                    <CusLink to={paths.PRIVACY}>隱私權保護政策</CusLink>
                                </li>
                                <li>
                                    <CusLink to={paths.SECURITY_POLICY}>網站安全政策</CusLink>
                                </li>
                                <li>
                                    <CusLink to={paths.COPYRIGHT}>網站資料開放宣告</CusLink>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h3>切換語系</h3>
                            <ul>
                                <li>
                                    <HrefLink href={paths.EN}>English</HrefLink>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </CusSection>
        </CusWrapper>
    )
}

export default Page
