import React, { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { CusWrapper, CusSection, CusBreadcrumb, CusLink, CusSteps, CusStepsCollapse } from 'src/components'
import { SectionSignHeaderLinks } from 'src/sections'
import { useParams, useSearchParams } from 'react-router-dom'
import { Space, Spin } from 'antd'
import { ReactComponent as FingerSvg } from 'src/assets/images/finger.svg'
import { CompanyCategoryNameByIdEnum, SignTypeNameByIdEnum, SignCategoryNameByIdEnum } from 'src/@types/Company'
import useAxios from 'src/hook/useAxios'
import paths from 'src/router/paths.config'
import { formatEditorContentHTML, setTableContainerMouseScroll } from 'src/utils'
import dayjs from 'dayjs'

const checkContent = (content: string) => {
    let state = false
    if (content) {
        const dom = document.createElement('div')
        dom.innerHTML = content
        if (dom.textContent) state = true
    }
    return state
}

const BusinessSign = () => {
    const { id, category = '', action, name, type = '' } = useParams()
    const defaultHeaderLinks = [
        { label: '申請流程', to: '?tab=flow', show: false },
        { label: '應備文件', to: '?tab=document', show: false },
        { label: '注意事項', to: '?tab=note', show: false },
        { label: '常見問題', to: type === '1' ? '/qna?category=1&page=1' : '/qna?category=2&page=1', show: false },
        { label: '相關法令', to: '?tab=decree', show: false },
        { label: '雲端登記平台', href: 'https://serv.gcis.nat.gov.tw/cfm/user/login/main.do', show: false },
    ]
    const [headerLinks, setHeaderLinks] = useState(defaultHeaderLinks)
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get('tab')
    const [isStepsContent, setIsStepsContent] = useState(true)
    const [content, setContent] = useState(null)

    const defaultDownloadURL = `/JoditBackend/DownloadAclick/${type}/`
    const downloadAPI = useRef({
        url: defaultDownloadURL,
        method: 'get',
        wait: true,
        responseType: 'blob',
        downloadFile: `${type === '1' ? '公司文件' : '商業文件'}-${dayjs().format('YYYYMMDD')}.zip`,
    })
    const [{ data: dataDownload, loading: loadingDownload, errorDownload }, executeDownload] = useAxios(downloadAPI.current)
    const [{ data: dataStep, loading: loadingStep, error: errorStep }, refetchStep] = useAxios({
        url: '/Registration/GetStep',
        loading: false,
        params: { id },
    })
    const [{ data: dataRequiredDocument, loading: loadingRequiredDocument, error: errorRequiredDocument }, refetchRequiredDocument] = useAxios({
        url: '/Registration/GetRequiredDocument',
        loading: false,
        params: { id },
    })
    const [{ data: dataAttentionMatter, loading: loadingAttentionMatter, error: errorAttentionMatter }, refetchAttentionMatter] = useAxios({
        url: '/Registration/GetAttentionMatter',
        loading: false,
        params: { id },
    })
    const [{ data: dataRelevantLaw, loading: loadingRelevantLaw, error: errorRelevantLaw }, refetchRelevantLaw] = useAxios({
        url: '/Registration/GetRelevantLaw',
        loading: false,
        params: { id },
    })
    const breadcrumb = useRef([
        {
            path: `/sign?step1=${type}`,
            breadcrumbName: SignTypeNameByIdEnum[type],
        },
        {
            path: `&step2=${category}`,
            breadcrumbName: SignCategoryNameByIdEnum[type][category],
        },
        {
            path: '',
            breadcrumbName: name,
        },
    ])

    const handleDownloadClick = async () => {
        downloadAPI.current.url = defaultDownloadURL + id
        await executeDownload()
    }

    const title = `${SignCategoryNameByIdEnum[type][category]}-${name}`
    const back = `/sign?step1=${type}&step2=${category}&step3=${action}`

    const [linkActive, setLinkActive] = useState(0)
    useEffect(() => {
        if (dataRequiredDocument || dataAttentionMatter || dataRelevantLaw) {
            setHeaderLinks(prev => {
                prev.map(row => {
                    if (row.to === '?tab=document') {
                        if (checkContent(dataRequiredDocument?.content)) row.show = true
                    } else if (row.to === '?tab=note') {
                        if (checkContent(dataAttentionMatter?.content)) row.show = true
                    } else if (row.to === '?tab=decree') {
                        if (checkContent(dataRelevantLaw?.content)) row.show = true
                    } else {
                        row.show = true
                    }
                })
                return prev
            })
        }
        if (tab === 'flow' || !tab) {
            setIsStepsContent(true)
            setContent(dataStep?.stepContents || [])
            setLinkActive(1)
        }
        if (tab === 'document') {
            setIsStepsContent(false)
            setContent(dataRequiredDocument?.content)
            setLinkActive(2)
        }
        if (tab === 'note') {
            setIsStepsContent(false)
            setContent(dataAttentionMatter?.content)
            setLinkActive(3)
        }
        if (tab === 'decree') {
            setIsStepsContent(false)
            setContent(dataRelevantLaw?.content)
            setLinkActive(5)
        }
    }, [searchParams, useSearchParams, dataStep, dataRequiredDocument, dataAttentionMatter, dataRelevantLaw])
    useEffect(() => {
        setTableContainerMouseScroll()
    }, [])
    return (
        <CusWrapper hideBreadcrumb metaTitle={title}>
            <CusSection backgroundImage={{ desktop: '/images/banner-business-info.png', mobile: { url: '/images/banner-business-info-mobile.png', position: 'center bottom' } }} height={350}>
                <CusBreadcrumb items={breadcrumb.current} />
                <h1>{title}</h1>
                <Space size={20}>
                    <CusLink type='button' theme='white-green' to={back}>
                        返回前頁
                    </CusLink>
                    <CusLink type='button' href={paths.SIGN_ONLINE} theme='green' icon={<FingerSvg style={{ marginBottom: -5 }} />} iconPosition='right'>
                        線上申辦規費減免！
                    </CusLink>
                </Space>
            </CusSection>
            <SectionSignHeaderLinks active={linkActive} items={headerLinks} type='registration' data={{ id }} onDownloadClick={handleDownloadClick} />
            <CusSection space={30}>
                <Spin spinning={loadingStep || loadingAttentionMatter || loadingRequiredDocument || loadingRelevantLaw}>
                    {loadingStep || loadingAttentionMatter || loadingRequiredDocument || loadingRelevantLaw ? (
                        <></>
                    ) : isStepsContent ? (
                        <CusStepsCollapse items={content || []} />
                    ) : (
                        <div className='cus-editor-content' dangerouslySetInnerHTML={{ __html: formatEditorContentHTML(content || '') }}></div>
                    )}
                </Spin>
            </CusSection>
        </CusWrapper>
    )
}

export default BusinessSign
