import { Spin, Space } from 'antd'
import React, { createContext, useState } from 'react'
import { LoadingContextType, LoadingProps } from 'src/@types/loading'
import { LoadingOutlined } from '@ant-design/icons'

const dec = (num: number): number => {
    if (num === 0) {
        throw Error('Cannot decrease loading count as current count is 0')
    }
    return --num
}
const inc = (num: number): number => ++num

const LoadingContext = createContext<LoadingContextType | null>(null)
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const LoadingProvider = (props: LoadingProps) => {
    const [count, setCount] = useState(0)

    const value: LoadingContextType = {
        loading: Boolean(count),
        setLoading: (start: boolean) => (start ? setCount(inc) : setCount(dec)),
    }

    return (
        <LoadingContext.Provider value={value}>
            {props.children}
            {value.loading ? (
                <div className='layout-loading-wrapper'>
                    <Spin indicator={antIcon} tip='Loading...'></Spin>
                </div>
            ) : null}
        </LoadingContext.Provider>
    )
}

export default LoadingContext
