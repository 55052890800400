import { Space } from 'antd'
import { useContext } from 'react'
import BreakpointContext from 'src/context/BreakpointContext'

interface DownloadItemProps {
    isFirst: boolean
    documentName: string
    version: string
    odtUrl: string
    pdfUrl: string
    docUrl: string
}
export default function DownloadItem(props: DownloadItemProps) {
    const breakpointContext = useContext(BreakpointContext)

    return (
        <div
            className='cus-download-item'
            role={'listitem'}
            style={{ padding: '20px 10px', border: '1px solid #C9C9C9', borderRight: 'none', borderLeft: 'none', borderTop: props.isFirst ? 'none' : undefined }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: breakpointContext?.isDesktop ? 'row' : 'column',
                    justifyContent: breakpointContext?.isDesktop ? 'space-between' : 'start',
                    alignItems: breakpointContext?.isDesktop ? 'center' : 'start',
                }}
            >
                <div className='cus-download-item-title' style={{ display: 'flex', wordBreak: 'break-all', marginBottom: breakpointContext?.isDesktop ? '' : '8px' }}>
                    <span style={{ color: '#202020', fontWeight: '500' }}>{props.documentName}</span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: breakpointContext?.isDesktop ? 'row' : 'column',
                        justifyContent: breakpointContext?.isDesktop ? 'space-between' : 'start',
                        alignItems: breakpointContext?.isDesktop ? 'center' : 'start',
                    }}
                >
                    <div className='cus-download-item-version' style={{ color: '#6A6A6A', marginRight: '60px', marginBottom: breakpointContext?.isDesktop ? '' : '16px' }}>
                        {props.version}版本
                    </div>
                    <Space size={breakpointContext?.isDesktop ? 30 : 18}>
                        <a
                            download={`${props.documentName}.doc`}
                            href={props.docUrl}
                            className='cus-link is-button'
                            style={{
                                minWidth: '99px',
                                padding: '8px 10px',
                            }}
                        >
                            doc 檔案
                        </a>
                        <a
                            download={`${props.documentName}.pdf`}
                            href={props.pdfUrl}
                            className='cus-link is-button'
                            style={{
                                minWidth: '99px',
                                padding: '8px 10px',
                            }}
                        >
                            pdf 檔案
                        </a>
                        <a
                            download={`${props.documentName}.odt`}
                            href={props.odtUrl}
                            className='cus-link is-button'
                            style={{
                                minWidth: '99px',
                                padding: '8px 10px',
                            }}
                        >
                            odt 檔案
                        </a>
                    </Space>
                </div>
            </div>
        </div>
    )
}
