import React from 'react'
import { Button } from 'antd'
import { CusSection } from '.'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

type Props = {
    value: CheckboxValueType[]
    onClick: any
}

const PopupDownload = (props: Props) => {
    const { value, onClick } = props
    return (
        <div className={`cus-popup-download${value.length ? ' is-show' : ''}`}>
            <CusSection>
                <p>已選擇 {value.length} 個業務</p>
                <Button type='text' onClick={() => onClick('clear')}>
                    取消選擇
                </Button>
                <Button type='primary' onClick={() => onClick('download')}>
                    文件下載
                </Button>
            </CusSection>
        </div>
    )
}

export default PopupDownload
