import React from 'react'
import { Space } from 'antd'
import { CusText } from 'src/components'
import { Link } from 'react-router-dom'
type Props = { title: string; img: string; items?: string[]; to?: any }

const Card = (props: Props) => {
    const { title, img, items = [], to = '' } = props

    return (
        <Link to={to} className='cus-business-card-item'>
            <Space direction='vertical' size={12} align='center'>
                <img src={img} alt={title} />
                <h4>{title}</h4>
                <Space align='start' direction='vertical' size={8}>
                    {items.map((row: string) => (
                        <CusText type='check' color='gray' key={row}>
                            {row}
                        </CusText>
                    ))}
                </Space>
            </Space>
        </Link>
    )
}
export default Card
