export enum CompanyCategoryEnum {
    shares = '1',
    limitedByShares = '2',
    sole = '4',
    partnership = '5',
}

export enum SignStep1Enum {
    company = '1',
    business = '2',
}
export const SignTypeNameByIdEnum: any = {
    '1': '公司登記',
    '2': '商業登記',
}

export const CompanyCategoryNameByIdEnum: any = {
    '1': '有限公司',
    '2': '股份有限公司',
}
export const SignCategoryNameByIdEnum: any = {
    '1': {
        '1': '有限公司',
        '2': '股份有限公司',
    },
    '2': {
        '4': '獨資組織',
        '5': '合夥組織',
    },
}
export const SignActionNameEnum: any = {
    '1': '設立登記',
    '2': '變更登記',
}

export enum CompanyActionIDEnum {
    new = '1',
    change = '2',
}
export enum CompanyActionNameEnum {
    new = '設立登記',
    change = '變更登記',
}

export const CompanyActionIDByNameEnum: any = {
    設立登記: '1',
    變更登記: '2',
}
