import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { CusWrapper, CusSection, CusLink, CusSteps, CusBusinessCard, CusBusinessCardContainer, CusBusinessItems, CusPopupDownload } from 'src/components'
import { Row, Col, Space, Checkbox, Spin } from 'antd'
import { SectionOtherNeed, SectionBusinessHeader } from 'src/sections'
import useRWD from 'src/hook/useRWD'
import { StyledGuideSpaceContainer } from './index.styled'
import useAxios from 'src/hook/useAxios'
import { CompanyCategoryEnum, CompanyActionIDEnum, SignCategoryNameByIdEnum, SignActionNameEnum, SignStep1Enum, SignTypeNameByIdEnum, CompanyActionNameEnum } from 'src/@types/Company'
import { StepItems } from 'src/@types/Steps'
import { scrollToMain } from 'src/utils'
import { PopupDownloadActionEnum } from 'src/@types/PopupDownload'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import dayjs from 'dayjs'

const disabledMap = {
    xor: ['停業', '復業', '延展開業', '合併解散', '解散', '裁定解散'],
    only: ['撤件'],
}

const BusinessHome = () => {
    const [searchParams] = useSearchParams()
    const [step, setStep] = useState(0)
    const { isMobile } = useRWD()
    const [{ data, loading, error }, refetch] = useAxios({ url: '/Registration/GuidedSearch', wait: true, method: 'post' })
    const navigate = useNavigate()
    const [metaTitle, setMetaTitle] = useState('')
    const [checked, setChecked] = useState<CheckboxValueType[]>([])
    const [disabledItems, setDisabledItems] = useState<string[]>([])

    const step1 = searchParams.get('step1') || ''
    const step2 = searchParams.get('step2') || ''
    const step3 = searchParams.get('step3') || ''
    const typeName = step1 === '1' ? '公司' : '商業'
    const defaultDownloadURL = `/JoditBackend/DownloadAclick/${step1}/`
    const downloadAPI = useRef({
        url: defaultDownloadURL,
        method: 'get',
        wait: true,
        responseType: 'blob',
        downloadFile: `${step1 === '1' ? '公司文件' : '商業文件'}-${dayjs().format('YYYYMMDD')}.zip`,
    })
    const [{ data: dataDownload, loading: loadingDownload }, executeDownload] = useAxios(downloadAPI.current)
    const dataObj = useMemo(() => {
        const payload = {} as any
        data &&
            data.forEach((row: any) => {
                payload[row.id] = row.title
            })
        return payload
    }, [data])

    const title = useMemo((): string => {
        let payload = ''
        if (step3) payload = '選擇辦理文件'
        else if (step2) payload = '選擇業務項目'
        else if (step1 === '1') payload = '選擇公司型態'
        else if (step1 === '2') payload = '選擇商業型態'
        else payload = '選擇公司或商業'

        setMetaTitle(payload)

        return payload
    }, [searchParams])

    const steps = useMemo((): StepItems => {
        return [
            {
                label: step1 ? SignTypeNameByIdEnum[step1] : '選擇公司或商業',
                to: '/sign',
            },
            {
                label: step2 ? SignCategoryNameByIdEnum[step1][step2] : '選擇型態',
                to: '?step1=' + searchParams.get('step1'),
            },
            {
                label: step3 ? SignActionNameEnum[step3] : isMobile ? `是否\n${CompanyActionNameEnum.new}` : `是否${CompanyActionNameEnum.new}`,
                to: `?step1=${searchParams.get('step1')}&step2=${searchParams.get('step2')}`,
            },
            {
                label: isMobile ? '選擇\n辦理文件' : '選擇辦理文件',
                to: `?step1=${searchParams.get('step1')}&step2=${searchParams.get('step2')}&step3=${searchParams.get('step3')}`,
            },
        ]
    }, [searchParams, isMobile])

    const handleCheckboxChange = (value: CheckboxValueType[]) => {
        setChecked(value)
    }
    const handlePopupDownloadClick = async (action: PopupDownloadActionEnum) => {
        try {
            if (action === 'clear') setChecked([])
            else {
                downloadAPI.current.url = defaultDownloadURL + checked.join(',')
                await executeDownload()
                setChecked([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const prevPath = useMemo(() => {
        let payload = ''
        if (step1) payload += '/sign'
        if (step2) payload += '?step1=' + step1
        if (step3) payload += '&step2=' + step2
        return payload
    }, [searchParams])

    const breadcrumb = useMemo(() => {
        const payload = [
            { breadcrumbName: step1 ? SignTypeNameByIdEnum[step1] : '選擇公司或商業', path: '/sign' },
            { breadcrumbName: step2 ? SignCategoryNameByIdEnum[step1][step2] : `選擇${typeName}型態`, path: `?step1=${step1}` },
            { breadcrumbName: step3 ? SignActionNameEnum[step3] : `選擇${typeName}型態`, path: `&step2=${step2}` },
            { breadcrumbName: '選擇辦理文件', path: '' },
        ]
        if (step3) payload.length = 4
        else if (step2) payload.length = 3
        else if (step1) payload.length = 2
        else payload.length = 1
        return payload
    }, [searchParams])

    useEffect(() => {
        if (step3) {
            setStep(4)

            refetch({
                registrationType: Number(step1),
                companyCategory: Number(step2),
                actionType: Number(step3),
            })
        } else if (step2) {
            setStep(3)
        } else if (step1) {
            setStep(2)
        } else setStep(1)

        window.scrollTo({ top: 0 })
        scrollToMain.init()
    }, [searchParams])

    const step4Items = useMemo(() => {
        if (data && data.length) {
            return data.map((row: any) => ({
                id: row.id,
                label: row.title,
                tip: row.tip,
                note: row.subTitle,
                index: String(row.number),
                to: `/sign/${step1}/${step2}/${step3}/${row.id}/${row.title}`,
            }))
        } else {
            return []
        }
    }, [data, searchParams])

    const handleStepsClick = (data: any, index: number) => {
        if (step > index + 1) {
            navigate(data.to)
        }
    }

    useEffect(() => {
        setDisabledItems([])
        for (let i = 0, length = checked.length; i <= length; i++) {
            const id = String(checked[i])
            if (dataObj[id]) {
                const xorIndex = disabledMap.xor.indexOf(dataObj[id])
                const onlyIndex = disabledMap.only.indexOf(dataObj[id])
                if (xorIndex > -1) {
                    const payload = [...disabledMap.xor]
                    payload.splice(xorIndex, 1)
                    setDisabledItems([...payload, ...disabledMap.only])
                    break
                } else if (onlyIndex > -1) {
                    const payload = [] as any
                    Object.keys(dataObj).forEach((id: string) => {
                        if (dataObj[id] !== disabledMap.only[onlyIndex]) payload.push(dataObj[id])
                    })
                    setDisabledItems(payload)
                    break
                } else {
                    setDisabledItems(disabledMap.only)
                }
            }
        }
    }, [dataObj, data, checked])
    return (
        <CusWrapper breadcrumb={breadcrumb} metaTitle={metaTitle}>
            <SectionBusinessHeader title={title} links={{ guide: `/sign?step1=${step1}`, all: `/sign/all/${step1}?tab=${searchParams.get('step2')}` }} hideLinks={!step1} active={0} />
            <CusSection theme='white' space={30}>
                <StyledGuideSpaceContainer isMobile={isMobile}>
                    <CusSteps value={step} theme='style2' items={steps} onClick={handleStepsClick} />
                    {step === 1 && (
                        <CusBusinessCardContainer>
                            <CusBusinessCard
                                to={`?step1=${SignStep1Enum.company}`}
                                title='公司登記'
                                img='/images/company-limited-shares.svg'
                                items={['全國性營利事業', '有大量的股東、商品賣到全台灣甚至國外']}
                            />
                            <CusBusinessCard
                                to={`?step1=${SignStep1Enum.business}`}
                                title='商業登記'
                                img='/images/company-limited.svg'
                                items={['地方性小本生意', '每個月營業額20萬元以下，可申請免用統一發票']}
                            />
                        </CusBusinessCardContainer>
                    )}
                    {step === 2 && step1 === '1' && (
                        <div className='has-other-link'>
                            <CusBusinessCardContainer>
                                <CusBusinessCard
                                    to={`?step1=${searchParams.get('step1')}&step2=${CompanyCategoryEnum.shares}`}
                                    title='有限公司'
                                    img='/images/company-limited.svg'
                                    items={['股東人數一人以上', '一人即可設立、架構較簡便、股權較穩定']}
                                />
                                <CusBusinessCard
                                    to={`?step1=${searchParams.get('step1')}&step2=${CompanyCategoryEnum.limitedByShares}`}
                                    title='股份有限公司'
                                    img='/images/company-limited-shares.svg'
                                    items={['法人或兩個自然人以上', '架構較完整、股權流動性高']}
                                />
                                {/* <CusBusinessCard to='?step2=3' title='無限、兩合公司' img='/images/company-unlimited.svg' items={['由無限責任股東和有限責任股東共同組成的', '詳細說明']} /> */}
                            </CusBusinessCardContainer>
                            <div className='other-link'>
                                <CusLink href='https://gcis.nat.gov.tw/mainNew/subclassNAction.do?method=getFile&pk=21' arrow='right'>
                                    外資公司、辦事處
                                </CusLink>
                            </div>
                            <div className='other-link'>
                                <CusLink href='https://gcis.nat.gov.tw/mainNew/classNAction.do?method=list&pkGcisClassN=15' arrow='right'>
                                    閉鎖性公司
                                </CusLink>
                            </div>
                            <CusLink type='button' theme='white' to={prevPath}>
                                上一步
                            </CusLink>
                        </div>
                    )}
                    {step === 2 && step1 === '2' && (
                        <div className='has-other-link'>
                            <CusBusinessCardContainer>
                                <CusBusinessCard
                                    to={`?step1=${searchParams.get('step1')}&step2=${CompanyCategoryEnum.sole}`}
                                    title='獨資組織'
                                    img='/images/business-sole-proprietorship.svg'
                                    items={['人數一人', '出資轉讓個人決定']}
                                />
                                <CusBusinessCard
                                    to={`?step1=${searchParams.get('step1')}&step2=${CompanyCategoryEnum.partnership}`}
                                    title='合夥組職'
                                    img='/images/business-partnership.svg'
                                    items={['人數二人以上', '出資轉讓需全體合夥人同意']}
                                />
                                {/* <CusBusinessCard to='?step2=3' title='無限、兩合公司' img='/images/company-unlimited.svg' items={['由無限責任股東和有限責任股東共同組成的', '詳細說明']} /> */}
                            </CusBusinessCardContainer>
                            <CusLink type='button' theme='white' to={prevPath}>
                                上一步
                            </CusLink>
                        </div>
                    )}
                    {step === 3 && (
                        <>
                            <CusBusinessCardContainer>
                                <CusBusinessCard
                                    to={`?step1=${searchParams.get('step1')}&step2=${searchParams.get('step2')}&step3=${CompanyActionIDEnum.new}`}
                                    title={CompanyActionNameEnum.new}
                                    img='/images/business-new.svg'
                                />
                                <CusBusinessCard
                                    to={`?step1=${searchParams.get('step1')}&step2=${searchParams.get('step2')}&step3=${CompanyActionIDEnum.change}`}
                                    title={CompanyActionNameEnum.change}
                                    img='/images/document-change.svg'
                                />
                            </CusBusinessCardContainer>
                            <CusLink type='button' theme='white' to={prevPath}>
                                上一步
                            </CusLink>
                        </>
                    )}
                    {step === 4 && (
                        <Spin spinning={loading}>
                            <Checkbox.Group style={{ width: '100%' }} onChange={handleCheckboxChange} value={checked}>
                                <CusBusinessItems title={SignActionNameEnum[step3]} items={step4Items} disabledItems={disabledItems} checkedItems={checked}></CusBusinessItems>
                            </Checkbox.Group>
                            <CusLink type='button' theme='white' to={prevPath}>
                                上一步
                            </CusLink>
                        </Spin>
                    )}
                </StyledGuideSpaceContainer>
            </CusSection>
            <SectionOtherNeed business={step1 === '2'} />
            <CusPopupDownload value={checked} onClick={handlePopupDownloadClick} />
        </CusWrapper>
    )
}

export default BusinessHome
