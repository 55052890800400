import { useContext, useMemo } from 'react'
import { ReactComponent as IconEmptyData } from 'src/assets/images/empty-data.svg'
import BreakpointContext from 'src/context/BreakpointContext'

interface EmptyDataProps {
    title: string
    size?: 'small' | 'big'
}
export default function EmptyData(props: EmptyDataProps) {
    const breakpointContext = useContext(BreakpointContext)

    const paddingStyles = useMemo(() => {
        if (props.size === 'small') {
            return {
                paddingTop: breakpointContext?.isDesktop ? '40px' : '50px',
                paddingBottom: breakpointContext?.isDesktop ? '40px' : '30px',
            }
        }

        return {
            paddingTop: breakpointContext?.isDesktop ? '200px' : '120px',
            paddingBottom: breakpointContext?.isDesktop ? '150px' : '80px',
        }
    }, [props, breakpointContext])

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                ...paddingStyles,
            }}
        >
            <IconEmptyData style={{ marginBottom: '20px' }} />
            <span style={{ color: '#202020' }}>{props.title}</span>
        </div>
    )
}
