import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CusWrapper, CusSection, CusBreadcrumb, CusLink, CusSteps, CusStepsCollapse } from 'src/components'
import { useSearchParams } from 'react-router-dom'
import { Space, Spin } from 'antd'
import useAxios from 'src/hook/useAxios'
import { formatEditorContentHTML, scrollToMain, setTableContainerMouseScroll } from 'src/utils'

const Page = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const type = searchParams.get('type') || 'company'
    const title = type === 'company' ? '公司登記申辦教學' : '商業登記申辦教學'
    const [step, setStep] = useState(1)
    const breadcrumb = useMemo(
        () => [
            {
                breadcrumbName: title,
            },
        ],
        [searchParams]
    )
    const back = `/transcription`

    const onStepChange = (data: any) => {
        setStep(data)
    }

    const dataFormat = (data: any) => {
        const payload = {
            content: data.guideContent,
            company: data.companySteps,
            business: data.businessSteps,
        }

        return payload
    }

    const api = {
        url: '/RegistrationGuide/GetData',
        format: dataFormat,
        loading: false,
        defaultData: { content: '', company: [], business: [] },
    }
    const [{ data, loading, error }, refetch] = useAxios(api)

    useEffect(() => {
        setStep(1)
    }, [searchParams])
    useEffect(() => {
        setTableContainerMouseScroll()
    }, [])

    return (
        <CusWrapper hideBreadcrumb className='page-teach' metaTitle={title}>
            <CusSection backgroundImage={{ desktop: '/images/banner-business-info.png', mobile: { url: '/images/banner-business-info-mobile.png', position: 'center bottom' } }} height={350}>
                <CusBreadcrumb items={breadcrumb} />
                <h1>
                    {title} <br></br>(懶人包)
                </h1>
            </CusSection>
            <CusSection id={scrollToMain.id} space={'40px 0 30px 0'}>
                <Space size={30}>
                    <CusLink type='button' theme={type === 'company' ? 'primary' : 'gray'} circle to='?type=company'>
                        公司登記申辦教學
                    </CusLink>
                    <CusLink type='button' circle theme={type === 'business' ? 'primary' : 'gray'} to='?type=business'>
                        商業登記申辦教學
                    </CusLink>
                </Space>
            </CusSection>
            <Spin spinning={loading}>
                {loading ? (
                    <></>
                ) : (
                    <>
                        <CusSection space={30}>
                            <div className='cus-editor-content' dangerouslySetInnerHTML={{ __html: formatEditorContentHTML(data.content) }}></div>
                        </CusSection>
                        <CusSection space={30}>
                            <h2>{type === 'business' ? '商業' : '公司'}設立流程</h2>
                            <CusStepsCollapse step={step} items={data[type] || []} stepsTheme='style3' onStepChange={onStepChange} />
                        </CusSection>
                    </>
                )}
            </Spin>
        </CusWrapper>
    )
}

export default Page
