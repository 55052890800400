import React, { useMemo } from 'react'
import useRWD from 'src/hook/useRWD'
type Props = {
    children?: React.ReactNode
    full?: boolean
    backgroundImage?: string | { desktop: string; mobile: string | { position: string; url: string } }
    height?: number | string
    className?: string
    style?: any
    space?: { lg?: string; md?: string; sm?: string } | number | string
    theme?: 'white' | 'blue'
    id?: string
}

const Section = (props: Props) => {
    const { children, full = false, backgroundImage = undefined, height = 'auto', className = '', style = {}, space = 0, theme, id } = props
    const { isPad, isDesktop, isMobile } = useRWD()

    const margin = useMemo(() => {
        if (typeof space === 'number') {
            return `${space}px 0`
        } else if (typeof space === 'string') {
            return space
        } else if (typeof space === 'object') {
            if (isDesktop) return space.lg
            if (isPad) return space.md
            if (isMobile) return space.sm
            return 0
        } else {
            return 0
        }
    }, [isPad, isDesktop, isMobile, space])

    const myClass = useMemo(() => {
        let payload = 'cus-section'
        if (full) payload += ' is-full'
        if (theme) payload += ` is-theme-${theme}`
        if (className) payload += ` ${className}`
        return payload
    }, [full, className, theme])

    const myBackgroundImage = useMemo(() => {
        if (typeof backgroundImage === 'string') {
            return backgroundImage
        } else if (typeof backgroundImage === 'object') {
            if (isMobile) {
                if (typeof backgroundImage.mobile === 'object') {
                    style.backgroundPosition = backgroundImage.mobile.position
                    return backgroundImage.mobile.url
                } else {
                    return backgroundImage.mobile
                }
            } else return backgroundImage.desktop
        } else {
            return null
        }
    }, [backgroundImage, isMobile])

    return (
        <section
            id={id}
            className={myClass}
            style={{
                ...(myBackgroundImage ? { backgroundImage: `url(${myBackgroundImage})` } : {}),
                height,
                margin,
                ...style,
            }}
        >
            <div className='cus-section-content'>{children}</div>
        </section>
    )
}

export default Section
