import styled, { createGlobalStyle, css } from 'styled-components'
import { Space } from 'antd'

interface Props {
    isMobile: boolean
}

export const StyledGuideSpaceContainer = styled.div<Props>`
    width: 100%;
    text-align: center;
    .cus-steps {
        margin-bottom: 50px;
    }
    .cus-link {
        margin-top: ${p => (p.isMobile ? '20px' : '50px')};
    }
    .cus-text {
        text-align: left;
    }
    .has-other-link {
        display: inline-block;
        .other-link {
            text-align: ${p => (p.isMobile ? 'center' : 'right')};
            font-size: ${p => (p.isMobile ? '16px' : 'inherit')};
            margin-top: 20px;
        }
        .cus-link {
            margin-top: 20px;
        }
    }
`
