import React, { useState, useEffect } from 'react'
import { Layout, Menu, Button, Space, Dropdown } from 'antd'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { CusSection, CusLink } from 'src/components'
import useRWD from 'src/hook/useRWD'
import { SettingOutlined } from '@ant-design/icons'
import { ReactComponent as IconPhone } from 'src/assets/images/icon-phone.svg'
import { ReactComponent as IconClock } from 'src/assets/images/icon-clock.svg'
import { ReactComponent as IconAddress } from 'src/assets/images/icon-address.svg'
import { ReactComponent as IconMenuArrow } from 'src/assets/images/icon-menu-arrow.svg'
import { ReactComponent as IconGoTop } from 'src/assets/images/icon-go-top.svg'
import type { MenuProps } from 'antd'
import { scrollToMain } from 'src/utils'
import paths from 'src/router/paths.config'
const { Header, Content, Footer } = Layout

type MenuItem = Required<MenuProps>['items'][number]
type fontsize = 'small' | 'normal' | 'large'
type TypeHeaderProps = {
    menus: MenuItem[]
    logo?: string
    menuSelectedKeys: string
    setFontsize?: any
    fontsize?: fontsize
}

const fontsizeName = {
    small: 'A-',
    normal: 'A',
    large: 'A+',
}

const HeaderDesktop = (props: TypeHeaderProps) => {
    const { menus, logo, menuSelectedKeys, setFontsize, fontsize = 'normal' } = props

    const items: MenuProps['items'] = [
        {
            key: 'small',
            label: (
                <button onClick={() => setFontsize('small')}>
                    A<span>-</span>
                </button>
            ),
        },
        {
            key: 'normal',
            label: <button onClick={() => setFontsize('normal')}>A</button>,
        },
        {
            key: 'large',
            label: (
                <button onClick={() => setFontsize('large')}>
                    A<span>+</span>
                </button>
            ),
        },
    ]

    return (
        <div id='layout-header' className='layout-header-desktop'>
            <div className='layout-header-link-container'>
                {/* <button onClick={() => document.getElementById('a')?.focus()}>a</button> */}
                {/* <button onClick={() => document.getElementById('go-main')?.focus()}>b</button> */}
                {/* <input type='text' id='a' /> */}
                <a id='go-main' href='javascript:void(0);' rel='noreferrer' title='跳到主要內容區塊' onClick={scrollToMain.move}>
                    跳到主要內容區塊
                </a>
                <CusSection>
                    <img
                        id='AU'
                        className='blind-brick'
                        src='/images/icon/blind-brick.svg'
                        width='12'
                        alt='導盲磚'
                        accessKey='U'
                        onClick={() => {
                            document.querySelector(`#AU`)?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                        }}
                    />
                    <div className='link-list'>
                        <CusLink to={paths.GUIDE}>網站導覽</CusLink>
                        <CusLink href={paths.EDBKCG} target='_blank'>
                            高雄市政府經濟發展局
                        </CusLink>
                        <CusLink href={paths.EN} target='_blank'>
                            EN
                        </CusLink>
                    </div>
                </CusSection>
            </div>
            <Header>
                <CusSection>
                    <div className='logo'>
                        <Link to='/'>
                            <img src={logo} alt='Logo' width='255' />
                        </Link>
                    </div>
                    <div className='layout-header-menu-container'>
                        <Menu mode='horizontal' items={menus} selectedKeys={[menuSelectedKeys]}></Menu>
                        <Space size='middle'>
                            <CusLink type='button' href={paths.CASE_PROCESS_SEARCH} theme='primary' size='small'>
                                案件進度查詢
                            </CusLink>
                            <Dropdown overlayClassName='cus-dropdown-fontsize-menu' menu={{ items, selectable: true, selectedKeys: [fontsize] }} placement='bottom' arrow={{ pointAtCenter: true }}>
                                <Button>{fontsizeName[fontsize]}</Button>
                            </Dropdown>
                        </Space>
                    </div>
                </CusSection>
            </Header>
        </div>
    )
}

const HamburgerButton = (props: { active: boolean; setActive: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { active, setActive } = props
    const handleClick = () => {
        setActive(prev => !prev)
    }
    return (
        <div className='hamburger-container'>
            <div className={`hamburger ${active ? 'is-active' : ''}`} onClick={handleClick}>
                <span className='bar bar1'></span>
                <span className='bar bar2'></span>
                <span className='bar bar3'></span>
            </div>
        </div>
    )
}

const HeaderMobile = (props: TypeHeaderProps) => {
    const { logo, menus, menuSelectedKeys } = props
    const [hamburgerActive, setHamburgerActive] = useState(false)

    const handleCloseMenu = () => {
        window.scrollTo({ top: 0 })
        setHamburgerActive(prev => !prev)
    }

    return (
        <div id='layout-header' className={`layout-header-mobile ${hamburgerActive ? 'is-active' : ''}`}>
            <div className='layout-header-container'>
                <Link to='/' className='logo'>
                    <img src={logo} alt='Logo' width='174' />
                </Link>
                <HamburgerButton active={hamburgerActive} setActive={setHamburgerActive} />
            </div>
            <div className='layout-header-menu-container'>
                <section className='layout-header-menu-section'>
                    <Menu mode='inline' inlineCollapsed={false} items={menus} onClick={handleCloseMenu} selectedKeys={[menuSelectedKeys]}></Menu>
                </section>
                <section className='other-menu-link-section'>
                    <CusLink arrow='right' href={paths.CASE_PROCESS_SEARCH}>
                        案件進度查詢
                    </CusLink>
                </section>
                <section className='footer-link-section'>
                    <div className='link-list'>
                        <CusLink to={paths.GUIDE}>網站導覽</CusLink>
                        <CusLink href={paths.EDBKCG} target='_blank'>
                            高雄市政府經濟發展局
                        </CusLink>
                        <CusLink href={paths.EN} target='_blank'>
                            EN
                        </CusLink>
                    </div>
                </section>
            </div>
        </div>
    )
}

function CustFooter() {
    const [showFooterList, showFooterListSet] = useState<number[]>([])

    return (
        <Footer>
            <CusSection>
                <div className='footer-container'>
                    <div
                        className='footer-blind-brick u-scroll-margin-top'
                        accessKey='L'
                        onClick={() => {
                            document.querySelector(`#AZ`)?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                        }}
                    >
                        <img
                            id='AZ'
                            src='/images/icon/blind-brick.svg'
                            width='12'
                            alt='導盲磚'
                            accessKey='Z'
                            onClick={() => {
                                document.querySelector(`#AZ`)?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                })
                            }}
                        />
                    </div>
                    <div className='footer-content-box'>
                        <div className='footer-content-col1'>
                            <div className='footer-content-col1-wrapper1'>
                                <img src={require('src/assets/images/footer-accessibility.png')} width='134' alt='Accessibility' />
                                <div className='footer-content-GOV'>
                                    <img src={require('src/assets/images/footer-GOV.png')} width='43' alt='GOV' />
                                </div>
                            </div>
                            <div className='footer-content-col1-wrapper2'>
                                <img src={require('src/assets/images/footer-QRCode.png')} width='80' alt='QRCode' />
                                <div className='footer-content-GOV'>
                                    <img src={require('src/assets/images/footer-GOV.png')} width='43' alt='GOV' />
                                </div>
                            </div>
                        </div>
                        <div className={`footer-content-text-wrapper ${showFooterList.includes(1) ? 'active' : ''}`}>
                            <h4>
                                公司登記
                                <div
                                    className='footer-content-icon'
                                    onClick={() => {
                                        if (showFooterList.includes(1)) {
                                            showFooterList.splice(showFooterList.indexOf(1), 1)
                                            showFooterListSet([...showFooterList])
                                        } else {
                                            showFooterListSet([...showFooterList, 1])
                                        }
                                    }}
                                >
                                    <div className='footer-content-icon-bar'></div>
                                    <div className='footer-content-icon-bar footer-content-icon-bar2'></div>
                                </div>
                            </h4>
                            <CusLink to={paths.SIGN_COMPANY_STEP2_1}>有限公司</CusLink>
                            <CusLink to={paths.SIGN_COMPANY_STEP2_2}>股份有限公司</CusLink>
                            <CusLink href={paths.LIMITED_PARTNERSHIP}>無限、兩合公司</CusLink>
                            <CusLink target='_blank' href={paths.FOREIGN_COMPANY}>
                                外資公司、辦事處
                            </CusLink>
                        </div>
                        <div className={`footer-content-text-wrapper ${showFooterList.includes(2) ? 'active' : ''}`}>
                            <h4>
                                商業登記
                                <div
                                    className='footer-content-icon'
                                    onClick={() => {
                                        if (showFooterList.includes(2)) {
                                            showFooterList.splice(showFooterList.indexOf(2), 1)
                                            showFooterListSet([...showFooterList])
                                        } else {
                                            showFooterListSet([...showFooterList, 2])
                                        }
                                    }}
                                >
                                    <div className='footer-content-icon-bar'></div>
                                    <div className='footer-content-icon-bar footer-content-icon-bar2'></div>
                                </div>
                            </h4>
                            <CusLink to={paths.SIGN_BUSINESS_STEP2_1}>獨資商業</CusLink>
                            <CusLink to={paths.SIGN_BUSINESS_STEP2_2}>合夥商業</CusLink>
                        </div>
                        <div className={`footer-content-text-wrapper ${showFooterList.includes(3) ? 'active' : ''}`}>
                            <h4>
                                相關服務
                                <div
                                    className='footer-content-icon'
                                    onClick={() => {
                                        if (showFooterList.includes(3)) {
                                            showFooterList.splice(showFooterList.indexOf(3), 1)
                                            showFooterListSet([...showFooterList])
                                        } else {
                                            showFooterListSet([...showFooterList, 3])
                                        }
                                    }}
                                >
                                    <div className='footer-content-icon-bar'></div>
                                    <div className='footer-content-icon-bar footer-content-icon-bar2'></div>
                                </div>
                            </h4>
                            <CusLink to={paths.GUIDE}>申辦教學</CusLink>
                            <CusLink to={paths.QA}>常見問題</CusLink>
                            <CusLink to={paths.DOWNLOAD}>表單下載</CusLink>
                        </div>
                        <div className={`footer-content-text-wrapper ${showFooterList.includes(4) ? 'active' : ''}`}>
                            <h4>
                                外部資源
                                <div
                                    className='footer-content-icon'
                                    onClick={() => {
                                        if (showFooterList.includes(4)) {
                                            showFooterList.splice(showFooterList.indexOf(4), 1)
                                            showFooterListSet([...showFooterList])
                                        } else {
                                            showFooterListSet([...showFooterList, 4])
                                        }
                                    }}
                                >
                                    <div className='footer-content-icon-bar'></div>
                                    <div className='footer-content-icon-bar footer-content-icon-bar2'></div>
                                </div>
                            </h4>
                            <CusLink href={paths.SIGN_COMPANY_NEED_DOCUMENT}>公司登記應備文件</CusLink>
                            <CusLink href={paths.CASE_PROCESS_SEARCH}>案件進度查詢</CusLink>
                            <CusLink href={paths.LAND_USE_CONTROL}>土地使用管制</CusLink>
                            <CusLink href={paths.COMPANY_SING_CLOUD}>公司雲端登記平台</CusLink>
                            <CusLink href={paths.FILE_COMPANY_SIGN_FLOW}>公司設立流程-EN</CusLink>
                            <CusLink href={paths.FILE_BUSINESS_SIGN_FLOW}>商業設立流程-EN</CusLink>
                            <CusLink to={paths.LAWS}>相關法令</CusLink>
                        </div>
                        <div className='footer-content-text-wrapper is-last active'>
                            <h4>聯絡我們</h4>
                            <span className='cus-link'>
                                <span className='cus-link-icon is-left'>
                                    <IconPhone />
                                </span>
                                公司登記：撥打<a href='tel:073368333'>07-336-8333</a>轉2197
                            </span>
                            <span className='cus-link'>
                                <span className='cus-link-icon is-left'>
                                    <IconPhone />
                                </span>
                                商業登記：撥打<a href='tel:07-336-8333'>07-336-8333</a>轉2199
                            </span>
                            <span className='cus-link'>
                                <span className='cus-link-icon is-left'>
                                    <IconPhone />
                                </span>
                                傳真：07-331-6033
                            </span>
                            <span className='cus-link'>
                                <span className='cus-link-icon is-left'>
                                    <IconClock />
                                </span>
                                週一至週五 08:00~17:30
                            </span>
                            <span className='cus-link'>
                                <span className='cus-link-icon is-left'>
                                    <IconAddress />
                                </span>
                                <a target='_blank' rel='noreferrer' href={paths.GOOGLE_MAP}>
                                    802高雄市苓雅區四維三路2號
                                </a>
                            </span>
                        </div>
                        <div className='footer-go-top'>
                            <IconGoTop
                                onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' })
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ height: '1px', background: '#fff' }}></div>
                    <div className='footer-bottom-box'>
                        <div className='footer-bottom-box2'>
                            <CusLink to={paths.PRIVACY}>隱私權保護政策</CusLink>
                            <span>|</span>
                            <CusLink to={paths.SECURITY_POLICY}>網站安全政策</CusLink>
                            <span>|</span>
                            <CusLink to={paths.COPYRIGHT}>網站資料開放宣告</CusLink>
                        </div>
                        <CusLink>©高雄公司商業登記網</CusLink>
                    </div>
                </div>
            </CusSection>
        </Footer>
    )
}

const logo = '/images/logo.png'
const menus: MenuProps['items'] = [
    { label: <CusLink to={paths.SIGN_COMPANY}>公司登記</CusLink>, key: paths.SIGN_COMPANY },
    { label: <CusLink to={paths.SIGN_BUSINESS}>商業登記</CusLink>, key: paths.SIGN_BUSINESS },
    { label: <CusLink to={paths.TEACH}>申辦教學</CusLink>, key: paths.TEACH },
    { label: <CusLink to={paths.QA}>常見問題</CusLink>, key: paths.QA },
    { label: <CusLink to={paths.DOWNLOAD}>表單下載</CusLink>, key: paths.DOWNLOAD },
    {
        label: '外部資源',
        key: '6',
        popupOffset: [-70, 20],
        popupClassName: 'cus-submenu-popup',
        // icon: <IconMenuArrow />,
        children: [
            { label: <CusLink href={paths.SIGN_COMPANY_NEED_DOCUMENT}>公司登記應備文件</CusLink>, key: '6-1' },
            { label: <CusLink href={paths.CASE_PROCESS_SEARCH}>案件進度查詢</CusLink>, key: '6-2' },
            { label: <CusLink href={paths.LAND_USE_CONTROL}>土地使用管制</CusLink>, key: '6-3' },
            { label: <CusLink href={paths.COMPANY_SING_CLOUD}>公司雲端登記平台</CusLink>, key: '6-4' },
            { label: <CusLink href={paths.FILE_COMPANY_SIGN_FLOW}>公司設立流程-EN</CusLink>, key: '6-5' },
            { label: <CusLink href={paths.FILE_BUSINESS_SIGN_FLOW}>商業設立流程-EN</CusLink>, key: '6-6' },
            { label: <CusLink to={paths.LAWS}>相關法令</CusLink>, key: '6-7' },
        ],
    },
]

const App: React.FC = () => {
    const { isMobile, isPad, isDesktop } = useRWD()
    const [contentStyle, setContentStyle] = useState({ marginTop: 120 })
    const [menuSelectedKeys, setMenuSelectedKeys] = useState('')
    const location = useLocation()
    const localFontSize = (localStorage.getItem('font-size') || 'normal') as fontsize
    const [fontsize, setFontsize] = useState(localFontSize)

    useEffect(() => {
        let hasSelected = false
        menus.forEach((menu: any) => {
            const path = location.pathname + location.search
            if (path.indexOf(menu?.key) === 0) {
                setMenuSelectedKeys(menu?.key)
                hasSelected = true
            } else if (path.indexOf('/sign/2') === 0 || path.indexOf('/sign/all/2') === 0) {
                // FIXME: router.config 需要優化，不要寫例外 path
                setMenuSelectedKeys(paths.SIGN_BUSINESS)
                hasSelected = true
            } else if (path.indexOf('/sign/1') === 0 || path.indexOf('/sign/all/1') === 0) {
                // FIXME: router.config 需要優化，不要寫例外 path
                setMenuSelectedKeys(paths.SIGN_COMPANY)
                hasSelected = true
            }
        })
        if (!hasSelected) setMenuSelectedKeys('')
    }, [location])
    useEffect(() => {
        window.scrollTo({ top: 0 })
        scrollToMain.init()
    }, [location.pathname])

    useEffect(() => {
        console.info('version:', process.env.REACT_APP_VERSION)
    }, [])

    useEffect(() => {
        const html = document.querySelector('html')
        localStorage.setItem('font-size', fontsize)
        if (html) {
            html.className = `cus-font-size-${fontsize}`
        }
    }, [fontsize])
    return (
        <Layout className={`layout`}>
            {isDesktop ? (
                <HeaderDesktop logo={logo} menus={menus} menuSelectedKeys={menuSelectedKeys} setFontsize={setFontsize} fontsize={fontsize} />
            ) : (
                <HeaderMobile logo={logo} menus={menus} menuSelectedKeys={menuSelectedKeys} />
            )}
            <Content>
                <div className='site-layout-content u-scroll-margin-top'>
                    <Outlet />
                </div>
            </Content>
            <CustFooter />
        </Layout>
    )
}

export default App
