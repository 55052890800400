import React from 'react'
import { CusWrapper, CusSection, CusLink } from 'src/components'
import { Row, Col } from 'antd'
import { ReactComponent as IconLinkOut } from 'src/assets/images/icon-link-out.svg'
import paths from 'src/router/paths.config'
const HrefLink = (props: { href?: string; children: React.ReactNode }) => {
    return (
        <CusLink href={props.href} icon={<IconLinkOut />} iconPosition='right' iconSpace={6}>
            {props.children}
        </CusLink>
    )
}

const Page = () => {
    return (
        <CusWrapper>
            <CusSection className='section-document-content'>
                <h1>網站安全政策</h1>
                <p>
                    「高雄市政府經濟發展局網站」（以下簡稱本網站）為保障您及本網站的資料安全，特別依照「個人資料保護法」之精神，擬定以下網站安全政策，以說明本網站在資訊安全方面的作法。
                    <br />
                    政策適用範圍：資訊存取控制網站安全措施與規範
                    <br />
                    任何未經授權而企圖上載或更改本單位所提供的各項服務及相關資訊的行為，都是嚴厲禁止而且可能觸犯法律。為了網站安全的目的和確保這項服務能夠繼續服務所有的網路使用者，本網站提供了以下的安全保護措施：
                </p>
                <ul>
                    <li>使用高雄市政府(以下簡稱本府)主幹網路入侵偵測系統，監控網路流量，以防止網站攻擊行為及提供告警。</li>
                    <li>利用本府防火牆防止非法入侵、破壞或竊取資料，以避免網站遭到非法使用，以保障您的權益。</li>
                    <li>本網站主機裝設掃毒軟體，定期掃毒，以提供使用者更安全的網頁瀏覽環境。</li>
                    <li>於本網站主機定期進行弱點掃描，檢視系統漏洞修補情形並進行修補。</li>
                    <li>機密性及敏感性的資料或文件，不存放在對外開放的網站網頁與資訊系統中，機密性文件不以電子郵件傳送。</li>
                    <li>建立系統備援設施，定期執行必要的資料、軟體備份及備援作業，以備發生災害或儲存媒體失效時，可迅速回復正常作業。</li>
                    <li>自動接收所有來自相關作業系統廠商或應用程式廠商所寄發的安全維護通知，並依照建議，安裝適當的修補程式(Patch)。</li>
                </ul>
                <p>
                    網際網路資料的傳輸不能保證百分之百的安全，本網站將努力保護本網站及您個人資料的安全，部分情況下會使用通行標準的SSL
                    保全系統，保障資料傳送的安全性。但由於資料傳輸過程牽涉您上網環境保全之良窳，我們並無法確保您傳送或接收本網站資料的安全，您須注意並承擔網路資料傳輸之風險。請您諒解此部份所造成的後果均非本網站所能控制範圍。
                    <br />
                    本網站資安政策的修改由於科技發展的迅速，相關法規訂定未臻完備前，以及未來可能難以預見的環境變遷等因素，本網站將會視需要修改網站上所提供的資安政策說明，以落實保障網路安全的立意。
                </p>
            </CusSection>
        </CusWrapper>
    )
}

export default Page
