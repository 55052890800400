import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import { BreakpointProvider } from './context/BreakpointContext'
import router from './router'
import './style/index.less'
import { LoadingProvider } from './context/LoadingContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <React.StrictMode>
    <LoadingProvider>
        <BreakpointProvider>
            <RouterProvider router={router} />
        </BreakpointProvider>
    </LoadingProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
