import React from 'react'

import Error404 from 'src/pages/Error404'
import Error500 from 'src/pages/Error500'

import Home from 'src/pages/Home'
import { SignAll, SignInfo, SignGuide } from 'src/pages/Sign'
import Teach from 'src/pages/Teach'
import Guide from 'src/pages/Guide'
import Privacy from 'src/pages/Privacy'
import SecurityPolicy from 'src/pages/SecurityPolicy'
import Copyright from 'src/pages/Copyright'
import QA from 'src/pages/QA'
import Service from 'src/pages/Service'
import Download from 'src/pages/Download'
import FullSearch from 'src/pages/Home/FullSearch'
import { TranscriptionAreaList, TranscriptionAreaInfo } from 'src/pages/TranscriptionArea'

export const routesConfig = [
    // public routes
    {
        path: '/',
        name: '首頁',
        element: <Home />,
    },
    {
        path: '/full-search',
        name: '全站搜尋',
        element: <FullSearch />,
    },
    {
        path: '/sign',
        name: '登記',
        children: [
            {
                index: true,
                name: '登記',
                element: <SignGuide />,
            },
            {
                path: 'guide',
                name: '選擇公司或商業',
                element: <SignGuide />,
            },
            {
                path: 'all/:type',
                name: '選擇公司或商業',
                element: <SignAll />,
            },
            {
                path: ':type/:category/:action/:id/:name',
                name: '公司設立',
                element: <SignInfo />,
            },
        ],
    },
    {
        path: '/teach',
        name: '公司登記申辦教學',
        element: <Teach />,
    },
    {
        path: '/qna',
        name: '常見問題',
        element: <QA />,
    },
    {
        path: '/download',
        name: '表單下載',
        element: <Download />,
    },
    {
        path: '/transcription',
        name: '查閱抄錄專區',
        children: [
            {
                index: true,
                element: <TranscriptionAreaList />,
                name: '查閱抄錄專區',
            },
            {
                path: '/transcription/:id/:name',
                element: <TranscriptionAreaInfo />,
                name: '公司登記查閱、複製、證明申請',
            },
        ],
    },
    {
        path: '/service',
        name: '便民服務',
        element: <Service />,
    },
    {
        path: '/guide',
        name: '網站導覽',
        element: <Guide />,
    },
    {
        path: '/privacy',
        name: '隱私權保護政策',
        element: <Privacy />,
    },
    {
        path: '/security-policy',
        name: '網站安全政策',
        element: <SecurityPolicy />,
    },
    {
        path: '/copyright',
        name: '網站資料開放宣告',
        element: <Copyright />,
    },

    { path: '500', element: <Error500 /> },
    // catch all
    { path: '*', element: <Error404 /> },
]
