import React, { useMemo } from 'react'
import { StepProps, StepItem, StepsProps, Style3StepItemsProps } from 'src/@types/Steps'

const Step = (props: StepProps) => {
    const { index, children, value, onClick, theme = 'default' } = props
    const myClass = useMemo(() => {
        let payload = `cus-steps-item`
        if (typeof value === 'number') {
            if (index === value) payload += ` is-checking`
            if (index < value) payload += ` is-checked`
            if (index > value) payload += ` is-uncheck`
        }
        return payload
    }, [value])

    return (
        <>
            {['style2'].includes(theme) && index > 1 && <div key={`dot-${index}`} className='split-dot'></div>}
            <div className={myClass} onClick={onClick}>
                <div className='cus-steps-item-bg'>
                    <span className='cus-steps-item-index'>{index}</span>
                    <span className='cus-steps-item-content'>{children}</span>
                </div>
            </div>
        </>
    )
}

const formatContent = (row: any) => {
    if (!row) return ''
    else if (row === 'string') return row
    else return row.label
}

const Steps = (props: StepsProps) => {
    const { items, theme, value, onClick = () => {} } = props

    const myClass = useMemo(() => {
        let payload = 'cus-steps'
        payload += theme ? ` is-theme-${theme}` : ` is-theme-default`
        return payload
    }, [theme])

    return (
        <div className={myClass}>
            <div className='cus-steps-space'>
                {items.map((row: StepItem, index: number) =>
                    theme === 'style3' ? (
                        index % 5 === 0 && (
                            <div className='cus-steps-space-row' key={'row-' + index}>
                                {[...Array(5).keys()].map((index2: number) =>
                                    items[index + index2] ? (
                                        <Step index={index + index2 + 1} value={value} onClick={() => onClick(items[index + index2], index + index2)} key={index + index2} theme={theme}>
                                            {formatContent(items[index + index2])}
                                        </Step>
                                    ) : null
                                )}
                            </div>
                        )
                    ) : (
                        <Step index={index + 1} value={value} onClick={() => onClick(row, index)} key={index} theme={theme}>
                            {formatContent(row)}
                        </Step>
                    )
                )}
            </div>
        </div>
    )
}

export default Steps
