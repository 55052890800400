import { useState, useMemo, useEffect, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Tabs, Divider, Input, Spin, Checkbox } from 'antd'
import { CusSection, CusBusinessItems, CusLink, CusPopupDownload } from 'src/components'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import useAxios from 'src/hook/useAxios'
import { CompanyActionIDByNameEnum, CompanyCategoryEnum } from 'src/@types/Company'
import { PopupDownloadActionEnum } from 'src/@types/PopupDownload'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import dayjs from 'dayjs'
type Tab = { key: string; label: string; href?: string }
type Props = {
    tabs: Tab[]
    // type: 'company' | 'business'
}

const disabledMap = {
    xor: ['停業', '復業', '延展開業', '合併解散', '解散', '裁定解散'],
    only: ['撤件'],
}

const AllDocuments = (props: Props) => {
    const { tabs } = props
    const [searchParams, setSearchParams] = useSearchParams()
    const { type } = useParams()
    const tab = searchParams.get('tab')
    const [active, setActive] = useState(tab && tab !== 'null' ? tab : type === '1' ? '1' : '4')
    const [keyword, setKeyword] = useState('')
    const [checked, setChecked] = useState<CheckboxValueType[]>([])
    const [disabledItems, setDisabledItems] = useState<string[]>([])
    const defaultDownloadURL = `/JoditBackend/DownloadAclick/${type}/`
    const downloadAPI = useRef({
        url: defaultDownloadURL,
        method: 'get',
        wait: true,
        responseType: 'blob',
        downloadFile: `${type === '1' ? '公司文件' : '商業文件'}-${dayjs().format('YYYYMMDD')}.zip`,
    })
    const [{ data: dataDownload, loading: loadingDownload }, executeDownload] = useAxios(downloadAPI.current)
    const api = useMemo(() => {
        if (type === '2') return 'BusinessSearch'
        if (type === '1') return 'CompanySearch'
    }, [type])

    const dataFormat = (data: any) => {
        let payload = []
        if (data && data.length) {
            payload = data.map((row: { type: string; documents: { id: number; title: string; number: string; subTitle: string; tip: string }[] }) => {
                return {
                    type: row.type,
                    documents: row.documents
                        ? row.documents?.map(document => {
                              return {
                                  id: document.id,
                                  index: document.number,
                                  label: document.title,
                                  note: document.subTitle,
                                  tip: document.tip,
                                  to: `/sign/${type}/${active}/${CompanyActionIDByNameEnum[row.type]}/${document.id}/${document.title}`,
                              }
                          })
                        : [],
                }
            })
        }
        return payload
    }

    const [{ data, loading, error }, refetch] = useAxios({ url: '/Registration/' + api, params: { companyCategory: active, keyword: '' }, format: dataFormat, wait: true })

    const dataObj = useMemo(() => {
        const payload = {} as any
        data &&
            data.forEach((row: any) => {
                row.documents &&
                    row.documents.forEach((document: any) => {
                        payload[document.id] = { name: document.label, type: row.type }
                    })
            })
        return payload
    }, [data])
    const onSearch = (value: any) => {
        if (value === keyword) {
            refetch({
                companyCategory: active,
                keyWord: keyword,
            })
        }
        setKeyword(value)
    }
    const onChange = (key: string) => {
        const tab = tabs.find((tab: any) => key === tab.key)
        if (tab?.href) {
            window.open(tab.href, '_blank')
        } else {
            if (!checked.length) {
                tab && setActive(tab.key)
            }
        }
    }

    const onTabClick = (data: any) => {}

    const handleTabPrevClick = () => {
        const tabIndex = tabs.findIndex((tab: Tab) => {
            return tab.key === active
        })
        const tab = tabs[tabIndex - 1]
        if (!tab?.href) setActive(tab.key)
    }
    const handleTabNextClick = () => {
        const tabIndex = tabs.findIndex((tab: Tab) => {
            return tab.key === active
        })
        const tab = tabs[tabIndex + 1]
        if (!tab?.href) setActive(tab.key)
    }

    const handleCheckboxChange = (value: CheckboxValueType[]) => {
        setChecked(value)
    }

    const LeftArrow = () => {
        return <LeftOutlined style={{ color: '#266AD8', fontSize: 18 }} onClick={handleTabPrevClick} />
    }
    const RightArrow = () => {
        return <RightOutlined style={{ color: '#266AD8', fontSize: 18 }} onClick={handleTabNextClick} />
    }

    const handlePopupDownloadClick = async (action: PopupDownloadActionEnum) => {
        try {
            if (action === 'clear') setChecked([])
            else {
                downloadAPI.current.url = defaultDownloadURL + checked.join(',')
                await executeDownload()
                setChecked([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const keyword = searchParams.get('keyword') || ''
        const companyCategory = searchParams.get('tab') || (type === '1' ? '1' : '4')
        setActive(companyCategory)
        setKeyword(keyword)

        const checked = searchParams.get('checked')
        if (checked) {
            setChecked(checked.split('-').map(row => Number(row)))
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        refetch({
            companyCategory: active,
            keyWord: keyword,
        })
    }, [active, keyword])

    useEffect(() => {
        setSearchParams({ tab: active, keyword, checked: checked.join('-') })
    }, [active, keyword, checked])

    useEffect(() => {
        setDisabledItems([])
        let payload: string[] = []
        for (let i = 0, length = checked.length; i <= length; i++) {
            const id = String(checked[i])
            if (dataObj[id]) {
                const xorIndex = disabledMap.xor.indexOf(dataObj[id].name)
                const onlyIndex = disabledMap.only.indexOf(dataObj[id].name)

                if (xorIndex > -1) {
                    payload = [...disabledMap.xor]
                    payload.splice(xorIndex, 1)
                    payload = [...payload, ...disabledMap.only]
                    break
                } else if (onlyIndex > -1) {
                    Object.keys(dataObj).forEach((id: string) => {
                        if (dataObj[id].name !== disabledMap.only[onlyIndex]) payload.push(dataObj[id].name)
                    })
                    break
                } else {
                    payload = [...disabledMap.only]
                }
            }
        }

        if (checked.length && dataObj[String(checked[0])]) {
            const type = dataObj[String(checked[0])].type
            Object.keys(dataObj).forEach((id: string) => {
                if (dataObj[id].type !== type) {
                    payload.push(dataObj[id].name)
                }
            })
        }
        setDisabledItems(payload)
    }, [dataObj, data, checked])

    return (
        <CusSection className='cus-section-all-documents' space={30}>
            <Tabs
                className={checked.length ? 'is-disabled' : ''}
                activeKey={active}
                onChange={onChange}
                type='card'
                items={tabs}
                tabBarExtraContent={{ left: <LeftArrow />, right: <RightArrow /> }}
                onTabClick={onTabClick}
            />
            <div className='all-document-tabs-content'>
                <Input.Search value={keyword} placeholder='搜尋您要查找的文件' enterButton allowClear onSearch={onSearch} onChange={(e: any) => setKeyword(e.target.value)}></Input.Search>
                <Divider />
                <Checkbox.Group style={{ width: '100%' }} onChange={handleCheckboxChange} value={checked}>
                    <Spin spinning={loading}>
                        {data && data.map((row: any) => <CusBusinessItems key={row.type} title={row.type} items={row.documents} disabledItems={disabledItems} checkedItems={checked} />)}
                    </Spin>
                </Checkbox.Group>
            </div>
            <CusPopupDownload value={checked} onClick={handlePopupDownloadClick} />
        </CusSection>
    )
}

export default AllDocuments
