import React from 'react'
import { CusWrapper, CusSection, CusBusinessItems, CusLink } from 'src/components'
import useAxios from 'src/hook/useAxios'

const dataFormat = (data: any) => {
    return data.map((row: any) => {
        return {
            label: row.title,
            tip: row.tip,
            note: row.subTitle,
            index: row.number,
            to: `/transcription/${row.id}/${row.title}`,
        }
    })
}

const Page = () => {
    const [{ data, loading, error }, refetch] = useAxios({ url: '/CheckoutCopy/Search', format: dataFormat, defaultData: [] })
    return (
        <CusWrapper>
            <CusSection space={'0 0 30px'}>
                <h1>查閱抄錄專區</h1>
            </CusSection>
            <CusSection theme='white'>
                <CusBusinessItems items={data} showCheckbox={false}></CusBusinessItems>
            </CusSection>
        </CusWrapper>
    )
}

export default Page
